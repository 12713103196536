<template>
    <div class="products">

        <searchBox v-if="show_search" :placeholder="'Search products'"
            @close="close_search()" @typing="search($event)"/>

        <div class="head">
            <div>Products</div>
            <div class="icons">
                <div class="icon" @click="show_search = true">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>
        <div class="main">
            <loading v-if="loading"/>
            <div v-else>
                <product v-for="(product, i) in products" :key="i" :product="product" />
            </div>
        </div>
        <userMenu/>
    </div>
</template>

<script>
import search from '../../icons/search.vue'

import searchBox from '../../components/util/search.vue'

import loading from '../../components/util/loading.vue'
import product from '../../components/user/products/product.vue'

import userMenu from '../../components/menu/user.vue'

export default {
    components: {
        search,
        searchBox,
        loading,
        product,
        userMenu
    },
    data() {
        return {
            show_search: false,
            loading: true,
            products: [],
            all_products: []
        }
    },
    mounted() {
        this.$http.post('/user/products')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.products = r.products
                this.all_products = r.products

                this.loading = false
            }
        })
    },
    methods: {
        
        close_search() {
            this.show_search = false

            this.products = this.all_products
        },
        search(e) {
            this.products = []
            for (let x of this.all_products) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.products.push(x)
                }
            }
        }
    }
}
</script>

<style scoped>
    

    .products {
        height: 100%;
    }
    .head {
        font-weight: 800;
        padding-left: 20px;
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 129px);
        overflow: auto;
    }
</style>