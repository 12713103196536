<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Delete Category</div>
                <div class="main" v-if="parseInt(category.products) == 0">
                    <div class="text">
                        Are you sure you want to delete this category <b> {{category.name}} </b>
                    </div>
                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="del" @click="remove()" v-else>Delete</div>
                </div>
                <div class="main" v-else>
                    <div class="text">
                        You can only delete a category when there are no products 
                    </div>
                    <div class="btn" @click="closepop()">Close</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import btn_loader from '../../util/btn_loader.vue'

export default {
    components: {
        btn_loader
    },
    props: ['popup', 'category'],
    data() {
        return {
            pop: false,
            loading: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        
        setTimeout(() => {
            this.pop = true
        }, 100);
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },
        remove() {
            this.loading = true

            let form = new FormData()
            form.append('category_id', this.category.category_id)

            this.$http.post('/admin/category/delete', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.closepop()
                    this.$emit('removed')
                }
            })
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
    }
    .load {
        background: none;
        background-color: tomato;
    }

    .text {
        margin-bottom: 20px;
    }

    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        background-color: var(--main) ;
    }
    .del {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        background-color: tomato ;
    }



        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -201px;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>