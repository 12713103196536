<template>
    <div class="customer">
        <div class="head">
            <div class="round">
                <div class="bold">{{ customer.name[0] }}</div>
            </div>
            <div>
                <div class="name">{{ customer.name }}</div>
                <div class="username">{{ customer.phone }}</div>
            </div>
        </div>
        <div class="main">
            <div class="item">
                <div class="v">{{ added }}</div>
                <div class="label">Added</div>
            </div>
            <div class="item bor_left">
                <div class="v">{{ customer.purchases }}</div>
                <div class="label">Purchases</div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: ['customer'],
    data() {
        return {
            added: ''
        }
    },
    mounted() {
        
        this.added = moment(this.customer.created).format('Do MMM, yyyy')
    }
}
</script>

<style scoped>
    

    .customer {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .head {
        display: flex;
        padding: 15px;
        gap: 15px;
        border-bottom: 1px solid #ddd;
    }
    .round {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        font-weight: 800;
        font-size: 18px;
        color: var(--main);
    }

    .name {
        font-weight: 600;
    }
    .username {
        font-size: 12px;
        margin-top: 1px;
        color: gray;
    }
    .price {
        display: flex;
        gap: 5px;
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }

    .options {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
    }
    .icon {
        fill: var(--main);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .del {
        fill: tomato;
    }


    .main {
        padding: 15px;
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .item {
        text-align: center;
    }
    .bor_left {
        border-left: 1px solid #ddd;
    }
    .v {
        font-weight: 600;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
</style>