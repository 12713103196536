<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Add staff</div>
                <div class="main">
                    
                    <div class="input">
                        <div class="label">Name</div>
                        <input type="text" placeholder="Enter staff name" v-model="name">
                    </div>
                    <div class="input">
                        <div class="label">Username</div>
                        <input type="text" placeholder="Enter staff username" v-model="username">
                    </div>
                    <div class="input">
                        <div class="label">Password</div>
                        <input type="text" placeholder="Enter staff password" v-model="password">
                    </div>
                    <div class="input">
                        <div class="label">Phone Number</div>
                        <input type="text" placeholder="Enter staff phone number" v-model="phone">
                    </div>

                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="btn" v-else @click="add()">Add</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import btn_loader from '../../util/btn_loader.vue';

export default {
    components: {
        btn_loader
    },
    data() {
        return {
            pop: false,

            loading: false,
            name: '',
            username: '',
            password: '',
            phone: ''
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        
        setTimeout(() => {
            this.pop = true
        }, 100);
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },

        add() {
            if (this.name == '' || this.username == '' || this.password == '' || this.phone == '') {
                this.empty(); return
            }
            
            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('username', this.username)
            form.append('password', this.password)
            form.append('phone', this.phone)

            this.$http.post('/admin/staff/add', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.success()
                    this.closepop()

                    this.$emit('new_staff', {
                        'created': r.date,
                        'user_id': r.user_id,
                        'name': this.name,
                        'username': this.username,
                        'phone': this.phone,
                        'sales': 0,
                        'customers': 0
                    })
                } else {
                    if (r.username) {
                        this.username_err()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
        username_err: {
            type: 'warn',
            title: '',
            message: 'This username has been used already'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Staff added'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        height: fit-content;
        box-sizing: border-box;
        overflow: auto;
    }


    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        /* color: gray; */
        font-size: 14px;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
    }

    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }
    

        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -423px;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>