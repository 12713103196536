<template>
    <div class="dashboard">
        <div class="head">Dashboard</div>
        <div class="main l" v-if="loading">
            <loading/>
        </div>
        <div class="main" v-else>
            
            <div class="grid">
                <div class="box box1" @click="go_to('/user/sales')">
                    <div class="box_head">
                        <div>
                            <div class="no">{{ sales_count }}</div>
                            <div class="text">Sales</div>
                        </div>
                        <div class="icon">
                            <sales :size="'15px'" :style="{'height': '18px'}"/>
                        </div>
                    </div>
                    <div class="small">Tap to View</div>
                </div>
                <div class="box box2" @click="go_to('/user/customers')">
                    <div class="box_head">
                        <div>
                            <div class="no">{{ customers_count }}</div>
                            <div class="text">Customers</div>
                        </div>
                        <div class="icon">
                            <customers :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="small">Tap to View</div>
                </div>
            </div>
            
            <div class="section">
                <div class="title">Recent Sales</div>

                <order v-for="order in recent_orders" 
                    :order="order" :key="order.order_id"/>
            </div>
            
            <div class="section sec2">
                <div class="title">My Account</div>
                <account/>
            </div>

            <div class="label">Klara Diamond IMS v {{ $root.$data.v }}</div>

        </div>
        <userMenu/>
    </div>
</template>

<script>
import moment from 'moment';

import loading from '../../components/util/loading.vue';

import userMenu from '../../components/menu/user.vue'

import sales from '../../icons/sales.vue';
import customers from '../../icons/customers.vue';

import order from '../../components/user/sales/order.vue';

import account from '../../components/user/dashboard/account.vue';

export default {
    components: {
        userMenu,
        loading,
        sales, customers,
        order, account
    },
    data() {
        return {
            loading: true,
            recent_orders: [],
            orders: [],
            customers_count: 0,
            sales_count: 0
        }
    },
    mounted() {
        this.$http.post('/user/dashboard')
        .then(data => {
            let r = data.data
            if (r.success) {
                
                this.customers_count = r.customers
                
                
                for (let i = 0; i < r.sales.length; i++) {

                    let sale = r.sales[i]
                        
                    if (sale.ordered) {
                        continue
                    }
                    r.sales[i]['ordered'] = true

                    let order = {
                        order_date: moment(sale.sale_created).format('Do MMM, yyyy - HH:mm'),
                        order_title: sale.order_title,
                        order_id: sale.order_id,
                        total_sale_price: parseFloat(sale.sale_sale_price) * parseFloat(sale.quantity),
                        total_cost_price: parseFloat(sale.sale_cost_price) * parseFloat(sale.quantity),
                        products: 1,
                        sales: [sale]
                    }

                    for (let j = 0; j < r.sales.length; j++) {
                        let loop_sale = r.sales[j]

                        if (loop_sale.ordered) {
                            continue
                        }

                        if (sale.order_id == loop_sale.order_id) {
                            
                            order.total_sale_price += parseFloat(loop_sale.sale_sale_price) * parseFloat(loop_sale.quantity)
                            order.total_cost_price += parseFloat(loop_sale.sale_cost_price) * parseFloat(loop_sale.quantity)
                            order.products += 1
                            order.sales.push(loop_sale)

                            r.sales[j]['ordered'] = true
                        }
                    }

                    this.orders.push(order)
                }
                this.sales_count = this.orders.length

                this.recent_orders = this.orders.slice(0, 10)
                this.loading = false
            }
            

        })
    },
    methods: {
        go_to(where) {
            this.$router.push(where)
        }
    }
}
</script>

<style scoped>
    
    .head {
        background-color: white;
        font-weight: 800;
        padding: 20px;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .main {
        height: calc(100vh - 114px);
        overflow: auto;
        margin-bottom: -15px;
    }
    .l {
        padding: 20px;
        box-sizing: border-box;
    }

    

    .grid {
        padding: 20px;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .box {
        /* background-color: white; */
        color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box1 { 
        background: linear-gradient(to top right, #7f00ff, #e100ff);
    }
    .box1 .icon {
        fill:  #7f00ff;
    }
    .box2 {
        background: linear-gradient(to top right, #b91d73, #f953c6);
    }
    .box2 .icon {
        fill: #f953c6;
    }



    .box_head {
        display: flex;
        /* align-items: center; */
        justify-content: space-between;
    }
    .no {
        /* margin-top: 20px; */
        font-weight: 800;
        font-size: 20px;
    }
    .text {
        margin-top: 5px;
        font-weight: 500;
        font-size: 14px;
    }
    .small {
        margin-top: 20px;
        font-size: 12px;
    }


    .section {
        padding: 20px;
        padding-top: 10px;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .sec2 {
        padding-top: 0px;
    }

    .label {
        background: linear-gradient(to left, var(--main), tomato);
        padding: 10px;
        padding-bottom: 25px;
        text-align: center;
        color: white;
    }
    
</style>