<template>
    <div class="sale">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Sale Details
        </div>
        <div class="main">
            <div class="box">
                <div class="image">
                    <img src="../../assets/images/hair.jpg" alt="">
                </div>
                <div class="product">{{sale.product.name}}</div>
                
                <div class="price">
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="total">
                    </vue-numeric>
                </div>

                <div class="flex">
                    <div class="label">Quantity:</div>
                    <div class="value">{{ sale.quantity }}</div>
                </div>
                <div class="flex">
                    <div class="label">Sale Price:</div>
                    
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="sale.sale_sale_price">
                    </vue-numeric>
                </div>
                <div class="flex">
                    <div class="label">Cost Price:</div>
                    
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="sale.sale_cost_price">
                    </vue-numeric>
                </div>
                <div class="flex">
                    <div class="label">Profit:</div>
                    
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="profit">
                    </vue-numeric>
                </div>
                <div class="flex">
                    <div class="label">Date:</div>
                    <div class="value">{{date}}</div>
                </div>
                <div class="flex">
                    <div class="label">Sale ID:</div>
                    <div class="value">{{sale.sale_id}}</div>
                </div>
                <div class="flex">
                    <div class="label">Staff:</div>
                    <div class="value">{{sale.staff.staff_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Payment:</div>
                    <div class="value">{{sale.payment_type}}</div>
                </div>
                <div class="flex">
                    <div class="label">Sender Name:</div>
                    <div class="value">{{sale.sender_name}}</div>
                </div>
                <div class="btn" @click="open_product()">View Product</div>
            </div>

            <div class="box2">
                <div class="title">Customer</div>
                <div class="flex">
                    <div class="label">Name:</div>
                    <div class="value">{{sale.customer.customer_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{sale.customer.customer_phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Total Purchase:</div>
                    <div class="value">{{ sale.customer.customer_purchases }}</div>
                </div>
                <div class="btn">View Customer</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import back from '../../icons/back.vue'

export default {
    components: {
        back
    },
    data() {
        return {
            sale: {},
            date: '',

            total: 0,
            profit: 0
        }
    },
    mounted() {
        let s = localStorage.getItem('sale')
        this.sale = JSON.parse(s)

        this.date = moment(this.sale.date).format('Do MMM, yyyy - HH:mm')

        this.total = this.sale.sale_sale_price * parseFloat(this.sale.quantity)
        this.profit = this.total - this.sale.sale_cost_price * parseFloat(this.sale.quantity)
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        open_product() {
            localStorage.setItem('product', JSON.stringify(this.sale.product))

            setTimeout(() => {
                this.$router.push('/admin/product/' + this.sale.product.product_id)
            }, 1000);
        }
    }
}
</script>

<style scoped>
    
    .sale {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }


    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        margin-top: 35px;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        margin-top: -55px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #edeef0;
        border: 5px solid white;
    }
    .image img {
        width: 120px;
    }
    .product {
        text-align: center;
        margin-top: 10px;
    }
    .price {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        gap: 15px;
        flex-wrap: wrap;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }


    .box2 {
        background-color: white;
        padding: 20px;
        margin-top: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        margin-bottom: 20px;
    }
    .btn {
        text-align: center;
        padding: 10px;
        color: white;
        margin-top: 20px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
</style>