<template>
    <div class="account">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Account Settings
        </div>
        <div class="main">
            <div class="round">
                <div class="image" v-if="is_image">
                    <img :src="image" alt="">
                </div>

                <div v-else> {{ $root.$data.name[0] }} </div>

                <div class="upload" @click="upload">
                    Click to change image
                </div>
            </div>
            <div class="box">
                <div class="title">Accout Info</div>
                <div class="input">
                    <div class="label">Name</div>
                    <input type="text" v-model="name" placeholder="Enter your name">
                </div>
                <div class="input">
                    <div class="label">Phone</div>
                    <input type="text" v-model="phone" placeholder="Enter your phone number">
                </div>

                <btn_loader :height="'38px'" v-if="loading"/>
                <div class="btn" @click="save()" v-else>Save</div>
            </div>
        </div>

        <input type="file" accept="image/*" class="hidden" ref="image" @change="handleupload($event)"/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('Klara')


import * as imageConversion from 'image-conversion';

import back from '../../icons/back.vue'
import btn_loader from '../../components/util/btn_loader.vue';

export default {
    
    components: {
        back, btn_loader
    },
    mounted() {
        this.is_image = this.$root.$data.dp ? true : false
        this.image = this.imgurl + this.$root.$data.dp
        this.name = this.$root.$data.name
        this.phone = this.$root.$data.phone ? this.$root.$data.phone : ''
    },
    data() {
        return {
            loading: false,

            is_image: false,
            image_file: null,
            image: '',
            name: '',
            phone: ''
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/dp/'
        }
    },
    methods: {
        
        back() {
            this.$router.go(-1)
        },

        upload() {
            this.$refs['image'].click()
        },
        remove_image() {
            this.is_image = false
            this.image = this.imgurl + this.$root.$data.dp
            this.image_file = null
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                
                this.compress(picture)
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result

                    this.$refs['image'].value = ''

                }
            }
        },

        compress(file) {
            let name = file.name
            
            imageConversion.compressAccurately(file, 70).then( res => {
                this.is_image = true

                this.image_file = new File([res], name)
            })
        },

        save() {
            if (this.name == '' || this.phone == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('phone', this.phone)
            form.append('is_image', this.is_image ? '1' : '0')
            if (this.is_image) {
                form.append('image', this.image_file)
            }

            this.$http.post('/admin/settings/account', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    this.$root.$data.dp = r.dp
                    this.$root.$data.name = this.name
                    this.$root.$data.phone = this.phone

                    db.collection('auth').doc({ id: 1 }).update({
                        'dp': r.dp,
                        'name': this.name,
                        'phone': this.phone
                    }).then(() => {
                        this.$router.go(-1)
                    })
                }
            })
        }

    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter your name and phone number'
        },
        success: {
            type: 'success',
            title: '',
            message: 'You info has been updated'
        }
    }
}
</script>

<style scoped>
        .page {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }

    
    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }

    

    .round {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        border: 5px solid #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase;

        color: var(--main);
        margin: 0 auto;

        position: relative;
        margin-bottom: 20px;
    }
    .image img {
        width: 150px;
    }
    .upload {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
        text-align: center;
        text-transform: none;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        font-weight: 400;
        cursor: pointer;
    }

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .title {
        font-weight: 800;
        margin-bottom: 20px;
    }
    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        background-color: white;
        padding: 0px 5px;
        font-size: 14px;
        font-weight: 500;
    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 10px;
        background-color: var(--main);
        border-radius: 5px;
        color: white;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
    }
    
    
    .hidden {
        display: none;
    }
</style>