<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size"  viewBox="0 -960 960 960" >
            <path d="M729.911-160Q701-160 680.5-180.562 660-201.125 660-230v-140q0-28.875 20.589-49.438Q701.177-440 
            730.089-440 759-440 779.5-419.438 800-398.875 800-370v140q0 28.875-20.589 49.438Q758.823-160 
            729.911-160Zm-250 0Q451-160 430.5-180.562 410-201.125 410-230v-500q0-28.875 20.589-49.438Q451.177-800 
            480.089-800 509-800 529.5-779.438 550-758.875 550-730v500q0 28.875-20.589 49.438Q508.823-160 
            479.911-160Zm-250 0Q201-160 180.5-180.562 160-201.125 160-230v-300q0-28.875 20.589-49.438Q201.177-600 
            230.089-600 259-600 279.5-579.438 300-558.875 300-530v300q0 28.875-20.589 49.438Q258.823-160 
            229.911-160Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>