<template>
    <div class="order">
        
        <div class="head">
            <div class="icon" @click.stop="show = !show" :class="[show ? 'rot' : '']">
                <arrow :size="'35px'" :style="{'height': '35px'}"/>
            </div>
            <div>
                <div class="name">{{ order.order_title }}</div>
                <div class="date">{{ order.order_date }}</div>
            </div>
            <div class="price">
                <div class="b">
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="order.total_sale_price">
                    </vue-numeric>
                </div>
                <div class="small">
                    Products {{ order.products }}
                </div>
            </div>
        </div>
        <div class="main" v-if="show">
            <sale v-for="(sale, i) in order.sales" :key="i" :sale="sale"/>
        </div>
        
        <div class="flex">
            <div class="label">Customer:</div>
            <div class="value">{{ order.sales[0].customer.customer_name }}</div>
        </div>
    </div>
</template>

<script>
import sale from './sale.vue';
import arrow from '../../../icons/arrow.vue';
export default {
    components: {
        sale, arrow
    },
    props: ['order'],
    data() {
        return {
            show: false
        }
    },
}
</script>

<style scoped>

.order {
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .head {
        background-color: white;
        padding: 15px;

        display: flex;
        align-items: center;
        gap: 20px;

        transition: .2s;
    }
    .head:active {
        /* background-color: #edeef0; */
    }

    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: var(--trans);

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        fill: var(--main);
    }
    .rot {
        transform: rotate(90deg);
    }
    
    .name {
        font-weight: 500;
    }
    .date {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
    
    .price {
        margin-left: auto;
    }
    .b {
        margin-left: auto;
        font-weight: 800;
    }
    .small {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }

    .sale {
        margin-bottom: 0px;
        border-radius: 0px;
        border-top: 1px solid #ddd;
    }

    
    .flex {
        background-color: white;
        border-top: 1px solid #ddd;
        padding: 7px 15px;
        font-size: 14px;
        display: flex;
        gap: 10px;
        color: gray;
    }
    .value {
        font-weight: 500;
    }
</style>