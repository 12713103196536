<template>
    <div class="customers">

        <searchBox v-if="show_search" :placeholder="'Search products'"
            @close="close_search()" @typing="search($event)"/>

        <div class="head">
            <div>Customers</div>
            <div class="icons">
                <div class="icon" @click="show_search = true">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>
        <div class="main">
            <loading v-if="loading"/>
            <div v-else>
                <customer v-for="customer in customers" 
                    :key="customer.customer_id" :customer="customer"/>
            </div>
        </div>
        <userMenu/>
    </div>
</template>

<script>
import search from '../../icons/search.vue'
import searchBox from '../../components/util/search.vue'

import loading from '../../components/util/loading.vue'

import customer from '../../components/user/customers/customer.vue'

import userMenu from '../../components/menu/user.vue'


export default {
    components: {
        search, searchBox,
        loading,
        customer,
        userMenu
    },
    data() {
        return {
            show_search: false,

            loading: true,
            all_customers: [],
            customers: []
        }
    },
    mounted() {
        this.$http.post('/user/customers')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.all_customers = r.customers
                this.customers = r.customers

                this.loading = false
            }
        })
    },
    methods: {
        close_search() {
            this.show_search = false

            this.customers = this.all_customers
        },
        search(e) {
            this.customers = []

            for (let x of this.all_customers) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.customers.push(x)
                }
            }
        }
    }
}
</script>

<style scoped>
    
    .customers {
        height: 100%;
    }

    .head {
        font-weight: 800;
        padding-left: 20px;
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 129px);
        overflow: auto;
    }
</style>