<template>
    <div class="order">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Order Details
        </div>
        <div class="main">
            <div class="box">
                <div class="title">{{ order.order_title }}</div>

                <div class="flex">
                    <div class="label">Date:</div>
                    <div class="value">{{ order.order_date }}</div>
                </div>
                <div class="flex">
                    <div class="label">Products:</div>
                    <div class="value">{{ order.products }}</div>
                </div>
                <div class="flex">
                    <div class="label">Total Sale Price:</div>
                    
                    <div class="value">
                        <vue-numeric read-only currency="$" :precision=2 separator="," 
                            v-model="order.total_sale_price">
                        </vue-numeric>
                    </div>
                </div>
                <div class="flex">
                    <div class="label">Total Cost Price:</div>
                    <div class="value">
                        <vue-numeric read-only currency="$" :precision=2 separator="," 
                            v-model="order.total_cost_price">
                        </vue-numeric>
                    </div>
                </div>
                <div class="flex">
                    <div class="label">Profit:</div>
                    <div class="value">
                        <vue-numeric read-only currency="$" :precision=2 separator="," 
                            v-model="profit">
                        </vue-numeric>
                    </div>
                </div>
                <div class="flex">
                    <div class="label">Order ID:</div>
                    <div class="value">{{ order.sales[0].order_id }}</div>
                </div>
                <div class="flex">
                    <div class="label">Staff:</div>
                    <div class="value">{{ order.sales[0].staff.staff_name }}</div>
                </div>
                <div class="flex">
                    <div class="label">Payment:</div>
                    <div class="value">{{ order.sales[0].payment_type }}</div>
                </div>
            </div>

            <div class="menu">
                <div class="item" :class="[current == 0 ? 'item_active' : '' ]" @click="current = 0">
                    Products
                </div>

                <div class="item" :class="[current == 1 ? 'item_active' : '' ]" @click="current = 1">
                    Customer
                </div>
            </div>

            <div v-if="current == 0" class="m">
                <sale v-for="sale in order.sales" :key="sale.sale_id" :sale="sale"/>
            </div>
            <div v-if="current == 1" class="m box">
                <div class="title">Customer</div>
                <div class="flex">
                    <div class="label">Name:</div>
                    <div class="value">{{ order.sales[0].customer.customer_name }}</div>
                </div>
                <div class="flex">
                    <div class="label">phone:</div>
                    <div class="value">{{ order.sales[0].customer.customer_phone }}</div>
                </div>
                <div class="flex">
                    <div class="label">Purchases:</div>
                    <div class="value">{{ order.sales[0].customer.customer_purchases }}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import moment from 'moment'

import back from '../../icons/back.vue'
import sale from '../../components/admin/sales/sale.vue'

export default {
    components: {
        back,
        sale
    },
    data() {
        return {
            order: {},
            current: 0,

            profit: 0
        }
    },
    mounted() {
        let o = localStorage.getItem('order')
        this.order = JSON.parse(o)

        this.profit = this.order.total_sale_price - this.order.total_cost_price

        console.log(this.order);
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>
    
    .order {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }


    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        /* padding: 20px; */
        box-sizing: border-box;
    }

    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        margin: 20px;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        gap: 15px;
        flex-wrap: wrap;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }


    .menu {
        display: grid;
        grid-template-columns: 50% 50%;
        background-color: white;
        /* margin: 20px -20px; */
        position: sticky;
        top: 0px;
        border-bottom: 1px solid #ddd;
    }
    .item {
        padding: 15px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
    }
    .item_active {
        color: var(--main);
        border-bottom-color: var(--main);
    }

    .m {
        padding: 20px;
    }
    .box2 {
        background-color: white;
    }
</style>