<template>
    <div class="category">
        <div class="title">
            Categories
        </div>
        <div class="box">
            <div class="item" @click="popup = true">
                <div class="round">
                    <add :size="'25px'" :style="{'height' : '25px'}"/>
                </div>
                <div class="text">Add</div>
            </div>
            <div class="item" v-for="(c, i) in ca" :key="c.category_id" @click="open_category(i)">
                <div class="round">
                    <div class="image" v-if="c.image">
                        <img :src="imgurl + c.image">
                    </div>
                    <div class="bold" v-else>{{c.name[0]}}</div>

                    <div class="no">{{ c.products }}</div>
                </div>
                <div class="text">{{ c.name }}</div>
            </div>
        </div>

        <category_add :popup="popup" @close="popup = false" 
            @new_category="new_category($event)" v-if="popup"/>
    </div>
</template>

<script>
import add from '../../../icons/add.vue'
import category_add from './category_add.vue'


export default {
    components: {
        add, category_add
    },
    props: ['ca'],
    data() {
        return {
            popup: false,
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/product_categories/'
        }
    },
    methods: {
        new_category(e) {
            this.ca.unshift(e)

            this.$emit('new_category', e)
        },
        open_category(i) {
            localStorage.setItem('category', JSON.stringify(this.ca[i]))

            setTimeout(() => {
                this.$router.push('/admin/category/' + this.ca[i].category_id)
            }, 1000);
        }
    }
}
</script>

<style scoped>
    .category {
        margin-bottom: 20px;
    }
    .title {
        font-weight: 600;
        margin-bottom: 20px;
    }
    .box {
        background-color: white;
        /* padding: 15px; */
        border-radius: 5px;

        display: flex;
        /* gap: 25px; */

        overflow: auto;
    }
    .item {
        text-align: center;
        width: fit-content;
        padding: 15px 15px;
        transition: .2s;
    }
    .item:active {
        background-color: #edeef0;
    }
    .round {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
    }
    .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
    }
    .image img {
        width: 40px;
    }
    .no {
        position: absolute;
        top: -3px;
        right: -3px;

        background-color: var(--main);
        color: white;
        width: 17px;
        height: 17px;
        border-radius: 50px;
        font-size: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text {
        font-size: 12px;
        /* font-weight: 500; */
        /* color: gray; */
        margin-top: 10px;
    }
    .bold {
        font-weight: 800;
        color: var(--main);
    }
</style>