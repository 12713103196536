<template>
    <div class="staff_box">
        <div class="head">
            <div class="round" @click="open_staff()">
                <div class="bold">{{ staff.name[0] }}</div>
            </div>
            <div @click="open_staff()">
                <div class="name">{{ staff.name }}</div>
                <div class="username">@{{ staff.username }}</div>
            </div>
            <div class="options">
                <div class="icon" @click="popup = true">
                    <edit :size="'20px'" :style="{'height': '20px'}"/>
                </div>
                <div class="icon del" @click="open_staff()" v-if="!staff.deleted">
                    <deleteIcon :size="'20px'" :style="{'height': '20px'}"/>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="item">
                <div class="v">{{ staff.sales }}</div>
                <div class="label">Sales</div>
            </div>
            <div class="item bor_left">
                <div class="v">{{ staff.customers }}</div>
                <div class="label">Customers</div>
            </div>
        </div>

        <edit_box :staff="staff" v-if="popup" 
            @close="popup = false" @updated="updated($event)"/>
        
        <delete_box :staff="staff" v-if="del_pop" 
            @close="del_pop = false" @removed="removed()"/>
    </div>
</template>

<script>
import deleteIcon from '../../../icons/delete.vue'
import edit from '../../../icons/edit.vue'

import edit_box from './edit.vue'
import delete_box from './delete.vue'

export default {
    props: ['staff'],
    
    components: {
        deleteIcon, edit,
        edit_box, delete_box
    },
    data() {
        return {
            popup: false,
            del_pop: false,
        }
    },
    methods: {
        
        open_staff() {
            localStorage.setItem('staff', JSON.stringify(this.staff))

            setTimeout(() => {
                this.$router.push('/admin/staff/' + this.staff.user_id)
            }, 1000);
        },
        updated(e) {
            this.staff.name = e.name
            this.staff.username = e.username
            this.staff.phone = e.phone
        },
        removed() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>


    .staff_box {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .head {
        display: flex;
        padding: 15px;
        gap: 15px;
        border-bottom: 1px solid #ddd;
    }
    .round {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        font-weight: 800;
        font-size: 18px;
        color: var(--main);
    }

    .name {
        font-weight: 600;
    }
    .username {
        font-size: 12px;
        margin-top: 1px;
        color: gray;
    }
    .price {
        display: flex;
        gap: 5px;
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }

    .options {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
    }
    .icon {
        fill: var(--main);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .del {
        fill: tomato;
    }


    .main {
        padding: 15px;
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .item {
        text-align: center;
    }
    .bor_left {
        border-left: 1px solid #ddd;
    }
    .v {
        font-weight: 600;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
</style>