<template>
    <div class="dashboard">
        <div class="head">Dashboard</div>
        <div class="main l" v-if="loading">
            <loading/>
        </div>
        <div class="main" v-else>
            <div class="swipe">
                
            
                <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
                    <swiper-slide class="slide">
                        <today :today="today"/>
                    </swiper-slide>
                    <swiper-slide class="slide">
                        <month :month="month"/>
                    </swiper-slide>
                    
                    <div class="swiper-pagination" slot="pagination"></div>

                </swiper>

            </div>
            <div class="grid">
                <div class="box box1" @click="go_to('/admin/store')">
                    <div class="box_head">
                        <div>
                            <div class="no">{{ products_count }}</div>
                            <div class="text">Products</div>
                        </div>
                        <div class="icon">
                            <product :size="'15px'" :style="{'height': '18px'}"/>
                        </div>
                    </div>
                    <div class="small">Tap to View</div>
                </div>
                <div class="box box2">
                    <div class="box_head" @click="go_to('/admin/sales')">
                        <div>
                            <div class="no">{{ sales_count }}</div>
                            <div class="text">Sales</div>
                        </div>
                        <div class="icon">
                            <sales :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="small">Tap to View</div>
                </div>
                <div class="box box3">
                    <div class="box_head" @click="go_to('/admin/staffs')">
                        <div>
                            <div class="no">{{ staffs_count }}</div>
                            <div class="text">Staffs</div>
                        </div>
                        <div class="icon">
                            <staff :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="small">Tap to View</div>
                </div>
                <div class="box box4">
                    <div class="box_head">
                        <div>
                            <div class="no">{{ customers_count }}</div>
                            <div class="text">Customers</div>
                        </div>
                        <div class="icon">
                            <customers :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="small">Tap to View</div>
                </div>
            </div>
            
            <orders :sales="sales"/>

            <div class="section">
                <div class="title">Recent Sales</div>

                <order v-for="order in recent_orders" 
                    :order="order" :key="order.order_id"/>
            </div>

            <div class="section sec2">
                <div class="title">My Account</div>
                <acccout/>
            </div>

            <div class="label">Klara Diamond IMS v {{ $root.$data.v }}</div>

        </div>
        <adminMenu/>
    </div>
</template>

<script>
import moment from 'moment';

import loading from '../../components/util/loading.vue';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import today from '../../components/admin/dashboard/today.vue'
import month from '../../components/admin/dashboard/month.vue';

import product from '../../icons/product.vue';
import sales from '../../icons/sales.vue';
import customers from '../../icons/customers.vue';
import staff from '../../icons/staff.vue';

import orders from '../../components/admin/dashboard/orders.vue';

import order from '../../components/admin/sales/order.vue';

import acccout from '../../components/admin/dashboard/acccout.vue';

import adminMenu from '../../components/menu/admin.vue'

export default {
    components: {
        Swiper, SwiperSlide,
        loading,
        today, month,
        product, sales, customers, staff,
        orders,
        order,
        acccout,
        adminMenu
    },
    data() {
        return {
            loading: true,
            
            swiperOptions: {
                // autoHeight: true,
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination',
                    bulletActiveClass: 'pagination-active',
                    clickable: true
                }
            },

            products_count: 0,
            sales_count: 0,
            staffs_count: 0,
            customers_count: 0,

            sales: [],
            orders: [],
            recent_orders: [],
            today: {
                sales: 0,
                sale_price: 0,
                cost_price: 0,
                profit: 0
            },
            month: {
                title: '',
                sales: 0,
                sale_price: 0,
                cost_price: 0,
                profit: 0
            }

        }
    },
    mounted() {
        console.log('mount');
        this.$http.post('/admin/dashboard')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.products_count = r.products
                this.staffs_count = r.staffs
                this.customers_count = r.customers

                let string_sale = JSON.stringify(r.sales)
                this.sales = JSON.parse(string_sale)

                
                for (let i = 0; i < r.sales.length; i++) {

                    let sale = r.sales[i]
                        
                    if (sale.ordered) {
                        continue
                    }
                    r.sales[i]['ordered'] = true

                    let order = {
                        order_date: moment(sale.sale_created).format('Do MMM, yyyy - HH:mm'),
                        order_title: sale.order_title,
                        order_id: sale.order_id,
                        total_sale_price: parseFloat(sale.sale_sale_price) * parseFloat(sale.quantity),
                        total_cost_price: parseFloat(sale.sale_cost_price) * parseFloat(sale.quantity),
                        products: 1,
                        sales: [sale]
                    }

                    for (let j = 0; j < r.sales.length; j++) {
                        let loop_sale = r.sales[j]

                        if (loop_sale.ordered) {
                            continue
                        }

                        if (sale.order_id == loop_sale.order_id) {
                            
                            order.total_sale_price += parseFloat(loop_sale.sale_sale_price) * parseFloat(loop_sale.quantity)
                            order.total_cost_price += parseFloat(loop_sale.sale_cost_price) * parseFloat(loop_sale.quantity)
                            order.products += 1
                            order.sales.push(loop_sale)

                            r.sales[j]['ordered'] = true
                        }
                    }

                    this.orders.push(order)
                }
                this.sales_count = this.orders.length


                let today_date = moment().format('Do MMM, yyyy')
                let today_moment = moment(today_date, 'Do MMM, yyyy')

                for (let x of this.orders) {

                    let order_moment = moment(x.order_date, 'Do MMM, yyyy - HH:mm')

                    if (today_moment.isBefore(order_moment)) {
                        this.today.sales += 1
                        this.today.sale_price += x.total_sale_price
                        this.today.cost_price += x.total_cost_price

                        let profit = x.total_sale_price - x.total_cost_price
                        this.today.profit += profit
                    } else {
                        break
                    }
                }

                let month_date = moment().format('MMM, yyyy')
                let month_moment = moment(month_date)
                this.month.title = moment().format('MMMM')

                for (let x of this.orders) {
                    let order_moment = moment(x.order_date, 'Do MMM, yyyy')

                    if (month_moment.isBefore(order_moment)) {

                        this.month.sales += 1
                        this.month.sale_price += x.total_sale_price
                        this.month.cost_price += x.total_cost_price

                        let profit = x.total_sale_price - x.total_cost_price
                        this.month.profit += profit
                    } else {
                        break
                    }
                }

                this.recent_orders = this.orders.slice(0, 10)
                this.loading = false

            }
        })
    },
    methods: {
        go_to(where) {
            this.$router.push(where)
        }
    }
}
</script>

<style scoped>
    .dashboard {

    }
    .head {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        font-weight: 800;
        padding: 20px;
        font-size: 18px;

        position: relative;
        z-index: 2;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    }
    .main {
        /* background-color: white; */
        height: calc(100vh - 114px);
        overflow: auto;
        margin-bottom: -15px;
    }
    .l {
        padding: 20px;
        box-sizing: border-box;
    }
    .swipe {
        padding: 20px;
        padding-bottom: 0px;
    }
    .swiper {
        padding-bottom: 25px;
    }
    .swiper-pagination {
        bottom: -3px;
    }


    .grid {
        padding: 20px;
        padding-top: 15px;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .box {
        /* background-color: white; */
        color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box1 { 
        background: linear-gradient(to top right, #7f00ff, #e100ff);
        /* background-color: #7f00ff; */
    }
    .box1 .icon {
        fill:  #7f00ff;
    }
    .box2 {
        background: linear-gradient(to top right, #b91d73, #f953c6);
        /* background-color: #642b73; */
    }
    .box2 .icon {
        fill: #f953c6;
    }
    .box3 {
        background: linear-gradient(to top right, #45b649, #dce35b);
        /* background-color: #45b649; */
    }
    .box3 .icon {
        fill: #dce35b;
    }
    .box4 {
        background: linear-gradient(to top right, #3a6073, #3a7bd5);
        /* background-color: #3a6073; */
    }
    .box4 .icon {
        fill: #3a7bd5;
    }



    .box_head {
        display: flex;
        /* align-items: center; */
        justify-content: space-between;
    }
    .no {
        /* margin-top: 20px; */
        font-weight: 800;
        font-size: 20px;
    }
    .text {
        margin-top: 5px;
        font-weight: 500;
        font-size: 14px;
    }
    .small {
        margin-top: 20px;
        font-size: 12px;
    }


    .section {
        padding: 20px;
        padding-top: 10px;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .sec2 {
        padding-top: 0px;
    }

    .label {
        background: linear-gradient(to left, var(--main), tomato);
        padding: 10px;
        padding-bottom: 25px;
        text-align: center;
        color: white;
    }
</style>