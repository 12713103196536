<template>
    <div class="box">
        <div class="customer">
            <div class="round">
                <div class="bold">{{ customer.name[0] }}</div>
            </div>
            <div>
                <div class="name">{{ customer.name }}</div>
                <div class="phone">@{{ customer.phone }}</div>
            </div>
            
            <div class="options">
                <div class="icon" @click="popup = true">
                    <edit :size="'20px'" :style="{'height': '20px'}"/>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="label">Purchases:</div>
            <div class="value">{{ customer.purchases }}</div>
        </div>

        
        <edit_box :customer="customer" v-if="popup" 
            @close="popup = false" @updated="updated($event)"/>
    </div>
</template>

<script>

import edit from '../../../icons/edit.vue'
import edit_box from './edit.vue'


export default {
    props: ['customer'],
    components: {
        edit, edit_box
    },
    data() {
        return {
            popup: false
        }
    },
    methods: {
        updated(e) {
            this.customer.name = e.name
            this.customer.phone = e.phone
        }
    }
}
</script>

<style scoped>
    
    .box {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .customer {
        display: flex;
        padding: 15px;
        gap: 15px;
        border-bottom: 1px solid #ddd;
    }
    .round {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        font-weight: 800;
        font-size: 18px;
        color: var(--main);
    }

    .name {
        font-weight: 600;
    }
    .phone {
        font-size: 12px;
        margin-top: 1px;
        color: gray;
    }

    .options {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
    }
    .icon {
        fill: var(--main);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .icon:active {
        background-color: #edeef0;
    }


    .flex {
        border-top: 1px solid #ddd;
        padding: 7px 15px;
        font-size: 14px;
        display: flex;
        gap: 10px;
        color: gray;
    }
    .value {
        font-weight: 500;
    }
</style>