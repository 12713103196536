<template>
    <div class="user">
        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .user {
        height: 100vh;
        background-color: #edeef0;
    }
    .main {
        height: calc(100vh - 0px);
    }
</style>