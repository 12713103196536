<template>
    <div class="home">
        <div class="center">
            <img src="../assets/images/logo.svg"/>
        </div>
        <div class="bottom">
            Klara Diamond
        </div>
    </div>
</template>

<script>

// import Localbase from 'localbase'
// let db = new Localbase('Klara')

export default {
    name: 'Home',
    components: {
        
    },
    mounted() {
        // db.collection('auth').doc({ id: 1 }).get().then(document => {
        //     if (document == undefined) {
        //         this.$root.$data.login = false
        //         setTimeout(() => {
        //             this.$router.push('/login')
        //         }, 1000);
        //     } else {
        //         this.$root.$data.login = true
        //         this.$root.$data.type = document.type
        //         this.$root.$data.name = document.name
        //         this.$root.$data.username = document.username
        //         this.$http.defaults.headers.common['Auth'] = document.key
                
        //         if (document.type == 'admin') {
        //             setTimeout(() => {
        //                 this.$router.push('/admin')
        //             }, 1000);
        //         } if (document.type == 'user') {
        //             setTimeout(() => {
        //                 this.$router.push('/user')
        //             }, 1000);
        //         }
        //     }
        // })
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
    .home {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100vh;

        position: relative;
    }
    .center {
        width: 100px;
        height: 100px;
        border-radius: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-top: 2px solid var(--main);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        fill: var(--main);
    }
    img {
        width: 50px;
    }
    .bottom {
        position: absolute;
        bottom: 0px;
        left: 0px;

        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        color: var(--main);
    }
</style>
