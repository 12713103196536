<template>
    <div class="staff">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Staff Details
        </div>
        <div class="main">
            <div class="box">
                <div class="round">
                    <div class="bold">{{staff.name[0]}}</div>
                </div>
                <div class="name">{{staff.name}}</div>

                <div class="flex">
                    <div class="label">Username:</div>
                    <div class="value">{{staff.username}}</div>
                </div>
                <div class="flex">
                    <div class="label">Phone:</div>
                    <div class="value">{{staff.phone}}</div>
                </div>
                <div class="flex">
                    <div class="label">Sales:</div>
                    <div class="value">{{staff.sales}}</div>
                </div>
                <div class="flex">
                    <div class="label">Customers:</div>
                    <div class="value">{{staff.customers}}</div>
                </div>
                <div class="flex">
                    <div class="label">Added:</div>
                    <div class="value">{{ date }}</div>
                </div>
                <div class="option">
                    <div class="btn" @click="popup = true">Edit</div>

                    <div class="click" v-if="staff.deleted">Deleted</div>
                    <div class="del" v-else @click="del_pop = true">Delete</div>
                </div>
                <div class="btn" @click="change = true">Change Password</div>
            </div>

            <div class="menu">
                <div class="item" :class="[current == 0 ? 'item_active' : '' ]" @click="current = 0">
                    Sales
                </div>

                <div class="item" :class="[current == 1 ? 'item_active' : '' ]" @click="current = 1">
                    Customers
                </div>
            </div>

            <div  class="all" v-if="current == 0">
                <loading v-if="loading" class="l"/>
                <div v-else>
                    <order v-for="(order, i) in orders" :key="i"  :order="order"/>
                </div>
            </div>
            <div class="all" v-if="current == 1">
                <loading v-if="loading" class="l"/>
                <div v-else>
                    <customer v-for="customer in customers" 
                        :key="customer.customer_id" :customer="customer"/>
                </div>
            </div>

        </div>

        <change_password :staff="staff" v-if="change"
            @close="change = false"/>

        <edit_box :staff="staff" v-if="popup" 
            @close="popup = false" @updated="updated($event)"/>
        
        <delete_box :staff="staff" v-if="del_pop" 
            @close="del_pop = false"  @removed="removed()"/>
    </div>
</template>

<script>
import moment from 'moment'

import back from '../../icons/back.vue'

import edit_box from '../../components/admin/staffs/edit.vue'
import delete_box from '../../components/admin/staffs/delete.vue'
import change_password from '../../components/admin/staffs/change_password.vue'

import loading from '../../components/util/loading.vue'
import order from '../../components/admin/sales/order.vue'
// import sale from '../../components/admin/sales/sale.vue'
import customer from '../../components/admin/customers/customer.vue'


export default {
    components: {
        back,
        edit_box, delete_box, change_password,
        loading,
        order, customer
    },
    data() {
        return {
            change: false,
            popup: false,
            del_pop: false,

            staff: {},
            date: '',

            loading: true,
            current: 0,
            orders: [],
            sales: [],
            customers: []
        }
    },
    mounted() {
        let c = localStorage.getItem('staff')
        this.staff = JSON.parse(c)

        this.date = moment(this.staff.created).format('Do MMM, yyyy - HH:mm')

        let form = new FormData()
        form.append('user_id', this.staff.user_id)
        this.$http.post('/admin/staff', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.sales = r.sales
                this.customers = r.customers

                for (let i = 0; i < r.sales.length; i++) {

                    let sale = r.sales[i]
                        
                    if (sale.ordered) {
                        continue
                    }
                    r.sales[i]['ordered'] = true

                    let order = {
                        order_date: moment(sale.sale_created).format('Do MMM, yyyy - HH:mm'),
                        order_title: sale.order_title,
                        order_id: sale.order_id,
                        total_sale_price: parseFloat(sale.sale_sale_price) * parseFloat(sale.quantity),
                        total_cost_price: parseFloat(sale.sale_cost_price) * parseFloat(sale.quantity),
                        products: 1,
                        sales: [sale]
                    }

                    for (let j = 0; j < r.sales.length; j++) {
                        let loop_sale = r.sales[j]
                    
                        if (loop_sale.ordered) {
                            continue
                        }

                        if (sale.order_id == loop_sale.order_id) {

                            console.log('equall');
                            
                            order.total_sale_price += parseFloat(loop_sale.sale_sale_price) * parseFloat(loop_sale.quantity)
                            order.total_cost_price += parseFloat(loop_sale.sale_cost_price) * parseFloat(loop_sale.quantity)
                            order.products += 1
                            order.sales.push(loop_sale)

                            r.sales[j]['ordered'] = true
                        }
                    }

                    this.orders.push(order)
                }
                console.log(this.orders);
                this.loading = false
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        updated(e) {
            this.staff.name = e.name
            this.staff.username = e.username
            this.staff.phone = e.phone
        },
        removed() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>

    .category {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }


    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        /* padding: 20px; */
        box-sizing: border-box;

        position: relative;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        margin: 0px 20px;
        margin-top: 55px;
        margin-bottom: 20px;
    }
    .round {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-top: -45px;
        border-radius: 50%;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        border: 5px solid white;
    }
    .bold {
        font-weight: 800;
        font-size: 18px;
        color: var(--main);
    }

    .name {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        gap: 15px;
        flex-wrap: wrap;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }

    .option {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .btn {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
    .click {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        background-color: #999;
    }
    .del {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        background-color: tomato;
        cursor: pointer;
    }

    .menu {
        display: grid;
        grid-template-columns: 50% 50%;
        background-color: white;
        /* margin: 20px -20px; */
        position: sticky;
        top: 0px;
        border-bottom: 1px solid #ddd;
    }
    .item {
        padding: 15px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
    }
    .item_active {
        color: var(--main);
        border-bottom-color: var(--main);
    }

    .all {
        padding: 20px;
    }
</style>