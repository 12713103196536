<template>
    <div class="month">
        <div class="title">
            {{ month.title}} Report
        </div>

        <div class="profit">
            + 
            <vue-numeric read-only currency="$" :precision=2 separator="," v-model="month.profit">
            </vue-numeric>
        </div>

        <div class="flex">
            <div>
                <div class="no">{{ month.sales }}</div>
                <div class="label">Sales</div>
            </div>
            <div>
                <div class="no">
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="month.sale_price">
                    </vue-numeric>
                </div>
                <div class="label">Sales Price</div>
            </div>
            <div>
                <div class="no">
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="month.cost_price">
                    </vue-numeric>
                </div>
                <div class="label">Costs Price</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['month']
}
</script>

<style scoped>

    .month {
        background-color: white;
        /* background: linear-gradient(to bottom right, white, var(--main), purple); */
        background: linear-gradient(to top right, #8e2de2, #4a00e0);
        color: white;
        border-radius: 5px;
        padding: 20px;

        

        position: relative;
    }
    .profit {
        position: absolute;
        top: 15px;
        right: 20px;
        background-color: white;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;
        color: #4a00e0;
        font-size: 14px;
    }
    .title {
        font-weight: 800;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
    }
    .no {
        font-weight: 800;
        font-size: 14px;
    }
    .label {
        margin-top: 5px;
        font-size: 12px;

    }
</style>