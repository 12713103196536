<template>
    <div class="account">
        <div class="head">
            <div class="round">{{ name[0] }}</div>
            <div>
                <div class="name">{{ name }}</div>
                <div class="username">@{{ username }}</div>
            </div>
        </div>
        <div class="flex">
            <div class="f" @click="open_settings()">
                <div class="icon">
                    <account_setting :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <div class="text">Settings</div>
            </div>
            <div class="f bor" @click="logout()">
                <div class="icon">
                    <logout :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <div class="text">Logout</div>
            </div>
        </div>
    </div>
</template>

<script>

import Localbase from 'localbase'
let db = new Localbase('Klara')

import account_setting from '../../../icons/account_setting.vue'
import logout from '../../../icons/logout.vue'

export default {
    components: {
        account_setting, logout
    },
    data() {
        return {
            name: '',
            username: ''
        }
    },
    mounted() {
        
        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document) {
                this.name = document.name
                this.username = document.username
            }
        })
    },
    methods: {
        open_settings() {
            this.$router.push('/user/settings')
        },
        logout() {
            db.collection('auth').doc({ id: 1 }).delete()
            .then(() => {
                this.$router.push('/')
            })
        }
    }
}
</script>

<style scoped>
    .account {
        /* margin: 20px; */
        margin-top: 20px;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
    }

    .head {
        display: flex;
        border-bottom: 1px solid #ddd;
        padding: 15px;
        gap: 20px;
    }
    .round {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--trans);
        color: var(--main);
        font-weight: 500;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .name {
        font-weight: 800;
    }
    .username {
        font-size: 12px;
        color: gray;
        margin-top: 3px;
    }


    .flex {
        display: grid;
        grid-template-columns: 50% calc(50% - 1px);
        /* padding: 0px 15px; */
    }
    .bor {
        border-left: 1px solid #ddd;
    }

    .f {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 15px;
        font-size: 14px;
        fill: #555;
        transition: .3s;
    }
    .f:active {
        background-color: #edeef0;
    }
</style>