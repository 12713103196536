<template>
    <div class="products">
        <div class="head">
            <div class="title">Products</div>
            <div class="option">
                <div class="icon" @click="popup = true">
                    <add :size="'20px'"/>
                    <div class="label">Add Product</div>
                </div>
                <!-- <div class="icon">
                    <filtericon :size="'20px'"/>
                </div> -->
            </div>
        </div>

        <product v-for="(product, i) in products" :key="product.product_id" :product="product" 
            :brands="brands" :categories="category" @removed="removed(i)"/>

        <router-link to="/admin/deleted_products">
            <div class="btn">Deleted Products</div>
        </router-link>

        <product_add :popup="popup" :categories="category" :brands="brands"
            v-if="popup" @close="popup = false" @new_product="$emit('new_product', $event)"/>
    </div>
</template>

<script>
import add from '../../../icons/add.vue'
// import filtericon from '../../../icons/filter.vue'

import product from './product.vue'
import product_add from './product_add.vue'

export default {
    components: {
        add, 
        // filtericon,
        product, product_add
    },
    props: ['products', 'category', 'brands'],
    data() {
        return {
            popup: false
        }
    },
    methods: {
        removed(i) {
            this.products.splice(i, 1)
        }
    }
}
</script>

<style scoped>
    .products {

    }
    
    .head {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-weight: 600;
    }
    .option {
        display: flex;
        gap: 20px;
    }
    .icon {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        font-weight: 600;
        background-color: white;
        padding: 5px 15px;
        border-radius: 5px;

        fill: var(--main);
        color: var(--main);

        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }

    .btn {
        padding: 12px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        font-size: 14px;
    }
</style>