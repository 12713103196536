<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="m289-572 167-267q5-7 11-10.5t14-3.5q8 0 14 3.5t11 10.5l167 267q5 8 4.5 16t-4.5 15q-4 7-10.6 
            11t-15.4 4H315q-8.914 0-15.6-4.125T289-541q-4-7-4.5-15t4.5-16ZM706-80q-72.5 0-123.25-50.75T532-254q0-72.5 
            50.75-123.25T706-428q72.5 0 123.25 50.75T880-254q0 72.5-50.75 123.25T706-80Zm-586-55v-244q0-12.75 
            8.625-21.375T150-409h244q12.75 0 21.375 8.625T424-379v244q0 12.75-8.625 21.375T394-105H150q-12.75 
            0-21.375-8.625T120-135Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>