<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Products</div>
                <div class="main">
                    <div class="product" v-for="(product, i) in all_products" :key="product.product_id">
                        <div class="head" @click="select(i, product.selected)">
                            <div class="image">
                                <img :src="imgurl + product.image" alt="">
                            </div>
                            <div>
                                <div class="name">{{product.name}}</div>
                                <div class="price">
                                    <vue-numeric read-only currency="$" :precision=2 separator="," 
                                        v-model="product.sale_price">
                                    </vue-numeric>
                                </div>
                            </div>
                            <div class="selected" v-if="product.selected">
                                <div class="select"></div>
                            </div>
                            <div class="not_selected" v-else></div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div class="text">Total: <b> {{total}} </b></div>
                    <div class="btn" @click="submit()">Submit</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: ['products'],
    data() {
        return {
            pop: false,

            total: 0,
            all_products: []
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/products/'
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        setTimeout(() => {
            this.pop = true
        }, 100);


        for (let x of this.products) {
            x.image = JSON.parse(x['images'])[0]
            if (x.selected) {
                this.total += 1
            }
            x.quantity = x.quantity ? x.quantity : 1
            this.all_products.push(x)
        }
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },
        select(i, current) {
            let new_p = this.all_products[i] 
            new_p.selected = !current
            
            if (!current) {
                new_p.quantity = 1
            }

            let new_all_products = this.all_products
            new_all_products[i] = new_p

            this.all_products = []
            this.all_products = new_all_products

            this.total = current ? this.total - 1 : this.total + 1

        },
        submit() {
            this.closepop()
            this.$emit('submitted')
        }
    }
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        height: fit-content;
        box-sizing: border-box;
        overflow: auto;
        height: 50vh;
        background-color: #edeef0;
    }
    .product {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        padding: 15px;
        gap: 15px;
        /* border-bottom: 1px solid #ddd; */
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    .image img {
        width: 40px;
    }

    .name {
        font-weight: 600;
    }
    .price {
        display: flex;
        gap: 5px;
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
    .not_selected {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--main);
        margin-left: auto;
        margin-right: 10px;
    }
    .selected {
        padding: 4px;
        border-radius: 50%;
        border: 2px solid var(--main);

        margin-left: auto;
        margin-right: 10px;
    }
    .select {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--main);
    }


    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }

    .bottom {
        padding: 20px;
    }
    .text {
        margin-bottom: 10px;
        font-size: 15px;
    }
    

        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: calc(-50vh - 100px);
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>