<template>
    <div class="admin">
        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    }
}
</script>

<style scoped>
    .admin {
        height: 100vh;
        background-color: #edeef0;
    }
    .main {
        height: calc(100vh - 0px);
    }
</style>