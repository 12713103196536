<template>
    <div class="orders">
        <div class="title">Order Overview</div>

        <div class="menu_con">
            <div class="menu">
                <div class="item" :class="[i == current ? 'a' : '' ]" @click="setgraph(i)"
                    v-for="(month, i) in months" :key="i">
                    {{ month.title }} {{ month.year != current_year ? current_year : '' }}
                </div>
            </div>
        </div>

        <div class="chart">
            <apexchart :key="updatekey" :width="width" type="area" 
                :options="chartOptions" :series="series"></apexchart>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['sales'],
    data() {
        return {
            updatekey: 0,
            width: window.innerWidth,
            chartOptions: {
                
                background: '#fff',
                stroke: {
                    curve: 'smooth',
                    width: '2',
                    colors: ['#4169e1']
                },
                fill: {
                    colors: ['#4169e1'],
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        opacityFrom: 0.8,
                        opacityTo: 0.1,
                    }
                },
                markers: {
                    size: 0,
                    colors: ['#fff'],
                    strokeColors: ['#45b649'],
                    strokeWidth: ['2']
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#ddd'
                },
                chart: {
                    id: 'vuechart-example',
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                        }
                    }
                },
                xaxis: {
                    
                    type: 'datetime',
                    min: 0,
                    max: 100000,
                    axisBorder: {
                        show: false
                    }
                }
            },
            series: [{
                name: 'Sales',
                data: [ ],
            }],

            current: 0,
            current_year: '',
            months: [],
            all_months: []
        }
    },
    mounted() {
        
        let current_year = moment().format('YYYY')
        this.current_year = current_year

        let months = []
        let month = {
            title: '',
            year: '',
            sales: [],
            orders: []
        }

        let last_known_year = moment().format('YYYY')
        let last_knwon_month = moment().format('MMMM')

        for (let x of this.sales) {

            let sale_year = moment(x.sale_created).format('YYYY')
            let sale_month = moment(x.sale_created).format('MMMM')


            x['ordered'] = false

            if (last_known_year == sale_year && last_knwon_month == sale_month) {
                
                month['title'] = moment(x.sale_created).format('MMM')
                month['year'] = sale_year
                month.sales.push(x)
            } else {
                
                if (month.sales.length > 0) { 
                    months.push(month)
                }

                month = {
                    title: moment(x.sale_created).format('MMM'),
                    year: sale_year,
                    sales: [x],
                    orders: []
                }
            }
            
            last_known_year = moment(x.created).format('YYYY')
            last_knwon_month = moment(x.created).format('MMMM')
        }

        months.push(month)

        let ordered_months = []


        for(let m of months) {
            for (let i = 0; i < m.sales.length; i++) {
                let sale = m.sales[i]
                
                if (sale.ordered) {
                    continue
                }
                m.sales[i].ordered = true

                let order = {
                    order_date: moment(sale.sale_created).format('Do MMM, yyyy - HH:mm'),
                    order_title: sale.order_title,
                    order_id: sale.order_id,
                    total_sale_price: parseFloat(sale.sale_sale_price) * parseFloat(sale.quantity),
                    total_cost_price: parseFloat(sale.sale_cost_price) * parseFloat(sale.quantity),
                    products: 1,
                    sales: [sale]
                }

                for (let j = 0; j < m.sales.length; j++) {
                    let loop_sale = m.sales[j]
                
                    if (loop_sale.ordered) {
                        continue
                    }

                    if (sale.order_id == loop_sale.order_id) {
                        
                        order.total_sale_price += parseFloat(loop_sale.sale_sale_price) * parseFloat(loop_sale.quantity)
                        order.total_cost_price += parseFloat(loop_sale.sale_cost_price) * parseFloat(loop_sale.quantity)
                        order.products += 1
                        order.sales.push(loop_sale)

                        m.sales[j].ordered = true
                    }
                }

                m.orders.push(order)
            }
            ordered_months.push(m)
        }

        for (let y of ordered_months) {
            let total_sale_price = 0
            let total_cost_price = 0

            for (let order of y.orders) {
                total_sale_price += order.total_sale_price
                total_cost_price += order.total_cost_price
            }

            y['total_sale_price'] = total_sale_price
            y['total_cost_price'] = total_cost_price
            y['profit'] = total_sale_price - total_cost_price

            this.months.push(y)
        }

        let mmmmm = JSON.stringify(this.months)
        this.all_months = JSON.parse(mmmmm)

        this.setgraph(0)

    },
    methods: {
        setgraph(to) {
            this.current = to

            let month = this.months[to]

            let min_moment = moment(`${month.year}-${month.title}`, 'YYYY-MMM')
            let days_in_month = min_moment.daysInMonth()

            let max_moment = moment(`${month.year}-${month.title}-${days_in_month}`, 'YYYY-MMM-DD')

            this.chartOptions = {...this.chartOptions, ...{
                xaxis: {
                    type: 'datetime',
                    min: min_moment.valueOf(),
                    max: max_moment.valueOf(),
                }
            }}

            let graph_data = []

            for (let x of month.orders) {
                let order_moment = moment(x.order_date, 'Do MMM, yyyy - HH:mm')


                graph_data.push([
                    order_moment.valueOf(), x.sales.length
                ])
            }

            

            this.series = [
                {
                    name: 'Sales',
                    data: graph_data
                }
            ]

        }
    }
}
</script>

<style scoped>
    .orders {
    }
    .title {
        padding: 20px;
        font-size: 18px;
        font-weight: 800;
        /* margin-bottom: 20px; */
    }
    .menu_con {
        display: flex;
        /* flex-direction: row-reverse; */
        
    }
    .menu {
        display: flex;
        /* flex-direction: row-reverse; */
        overflow: auto;
        gap: 20px;
        padding: 0px 20px;
        
        position: relative;
        width: 100%;
    }
    .item {
        padding: 5px 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        font-size: 14px;
        font-weight: 500;
    }
    .a {
        background-color: var(--main);
        color: white;
        border-color: var(--main);

        /* position: sticky; */
        left: 0px;
        right: 0px;
    }
    .chart {
        /* color: #4169e1; */
        /* background-color: white; */
    }
</style>