<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M180-80q-24.75 0-42.375-17.625T120-140v-483q-17-6-28.5-21.388Q80-659.776 80-680v-140q0-24.75 
            17.625-42.375T140-880h680q24.75 0 42.375 17.625T880-820v140q0 20.224-11.5 35.612Q857-629 840-623v483q0 
            24.75-17.625 42.375T780-80H180Zm-40-600h680v-140H140v140Zm250 260h180q12.75 0 21.375-8.675 8.625-8.676 
            8.625-21.5 0-12.825-8.625-21.325T570-480H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 
            8.625 21.325T390-420Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>