<template>
    <div class="product">
        <div class="head">
            <div class="image" @click="open_product()">
                <img :src="imgurl + image" alt="">
            </div>
            <div @click="open_product()">
                <div class="name">{{product.name}}</div>
                <div class="price">
                    
                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="product.cost_price">
                    </vue-numeric>

                    <div>-</div>

                    <vue-numeric read-only currency="$" :precision=2 separator="," v-model="product.sale_price">
                    </vue-numeric>
                </div>
            </div>
            <div class="options" v-if="!product.deleted">
                <div class="icon" @click="popup = true">
                    <edit :size="'20px'" :style="{'height': '20px'}"/>
                </div>
                <div class="icon del" @click="del_pop = true">
                    <deleteIcon :size="'20px'" :style="{'height': '20px'}"/>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="item">
                <div class="v">{{product.stock}}</div>
                <div class="label">Stock</div>
            </div>
            <div class="item bor_left">
                <div class="v">{{product.quantity_sold}}</div>
                <div class="label">Qty S</div>
            </div>
            <div class="item bor_left">
                <div class="v">{{product.quantity_alert}}</div>
                <div class="label">Qty A</div>
            </div>
            <div class="item bor_left">
                <div class="v">{{product.sku}}</div>
                <div class="label">SKU</div>
            </div>
        </div>

        <product_edit :popup="popup" :product="product" :brands="brands" :categories="categories"
            v-if="popup" @close="popup = false"/>

        <product_delete :popup="del_pop" :product="product"
            v-if="del_pop" @close="del_pop = false" @removed="$emit('removed')"/>
    </div>
</template>

<script>
import deleteIcon from '../../../icons/delete.vue'
import edit from '../../../icons/edit.vue'

import product_edit from './product_edit.vue'
import product_delete from './product_delete.vue'


export default {
    components: {
        deleteIcon, edit,
        product_edit, product_delete
    },
    props: ['product', 'categories', 'brands'],
    data() {
        return {
            popup: false,
            del_pop: false,

            image: ''
        }
    },
    mounted() {
        let images = JSON.parse(this.product['images'])
        this.image = images[0]
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/products/'
        }
    },
    methods: {
        open_product() {
            localStorage.setItem('product', JSON.stringify(this.product))

            setTimeout(() => {
                this.$router.push('/admin/product/' + this.product.product_id)
            }, 1000);
        }
    }
}
</script>

<style scoped>
    .product {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .head {
        display: flex;
        padding: 15px;
        gap: 15px;
        border-bottom: 1px solid #ddd;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    .image img {
        width: 40px;
    }

    .name {
        font-weight: 600;
    }
    .price {
        display: flex;
        gap: 5px;
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }

    .options {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: auto;
    }
    .icon {
        fill: var(--main);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .del {
        fill: tomato;
    }


    .main {
        padding: 15px;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
    }
    .item {
        text-align: center;
    }
    .bor_left {
        border-left: 1px solid #ddd;
    }
    .v {
        font-weight: 600;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
</style>