<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M433-433H222q-19.75 0-33.375-13.675Q175-460.351 175-480.175 175-500 188.625-513.5 
                202.25-527 222-527h211v-211q0-19.625 13.675-33.812Q460.351-786 480.175-786 500-786 513.5-771.812 
                527-757.625 527-738v211h211q19.625 0 33.812 13.675Q786-499.649 786-479.825 786-460 771.812-446.5 
                757.625-433 738-433H527v211q0 19.75-13.675 33.375Q499.649-175 479.825-175 460-175 446.5-188.625 
                433-202.25 433-222v-211Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>