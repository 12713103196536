<template>
    <div class="page">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'"/>
            </div>
            Deleted Products
        </div>
        <div class="main">
            <loading v-if="loading"/>

            <div v-else>
                <product v-for="(product, i) in products" :key="product.product_id" :product="product" 
                    :brands="brands" :categories="category" @removed="removed(i)"/>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

import loading from '../../components/util/loading.vue';

import product from '../../components/admin/store/product.vue';

export default {
    components: {
        back,
        loading,
        product
    },
    data() {
        return {
            loading: true,
            products: [],
            categories: [],
            brands: []
        }
    },
    mounted() {
        this.$http.post('/admin/store/deleted')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.products = r.products
                this.categories = r.categories
                this.brands = r.brands

                this.loading = false
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },

    }
}
</script>

<style scoped>
    
    .page {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }

    
    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }
</style>