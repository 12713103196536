<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Add Vendor</div>
                <div class="main">
                    <div class="image" v-if="is_image">
                        <img :src="image"/>

                        <div class="remove" @click="remove_image()">
                            <remove :size="'30px'"/>
                        </div>
                    </div>
                    <div class="upload" @click="upload()" v-else>
                        <div class="upload_text">Add vendor image</div>
                    </div>
                    <div class="input">
                        <div class="label">Vendor</div>
                        <input type="text" placeholder="Enter Vendor" v-model="name">
                    </div>
                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="btn" v-else @click="add()">Add</div>
                </div>
            </div>
        </transition>

        
        <input type="file" accept="image/*" class="hidden" ref="image" @change="handleupload($event)"/>
    </div>
</template>

<script>
import * as imageConversion from 'image-conversion';

import remove from '../../../icons/remove.vue';
import btn_loader from '../../util/btn_loader.vue';

export default {
    components: {
        remove, btn_loader
    },
    props: ['popup'],
    data() {
        return {
            pop: false,

            loading: false,

            is_image: false,
            image_file: null,
            image: null,
            name: ''
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        
        setTimeout(() => {
            this.pop = true
        }, 100);
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },

        upload() {
            this.$refs['image'].click()
        },
        remove_image() {
            this.is_image = false
            this.image = ''
            this.image_file = null
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                
                this.compress(picture)
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result

                    this.$refs['image'].value = ''

                }
            }
        },
        compress(file) {
            let name = file.name
            
            imageConversion.compressAccurately(file, 70).then( res => {
                this.is_image = true

                this.image_file = new File([res], name)
            })
        },


        add() {
            if (!this.is_image || this.name == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('image', this.image_file)

            this.$http.post('/admin/brand/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    this.$emit('new_brand', {
                        'name': this.name,
                        'image': r.image,
                        'brand_id': r.brand_id,
                        'created': r.created,
                        'products': 0,
                        'sales': 0
                    })

                    this.closepop()

                } else {
                    if (this.name) {
                        this.name_err()
                    }
                }
                
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter vendor name and image'
        },
        name_err: {
            type: 'warn',
            title: '',
            message: 'This vendor has been added already'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Vendor added'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
    }

    .image {
        background-color: #edeef0;
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;

        position: relative;
    }
    .image img {
        width: 100%;
    }
    .remove {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        fill: var(--main);
    }

    .upload {
        height: 300px;
        background-color: #edeef0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .upload_text {
        font-size: 14px;
    }

    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        /* color: gray; */
        font-size: 14px;
        font-weight: 500;

    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }



        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -213px;
        }
        to {
            margin-bottom: 0px;
        }
    }
    
    
    .hidden {
        display: none;
    }
</style>