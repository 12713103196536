import Vue from 'vue'
import App from './App.vue'
import router from './router'
import notify from './external/notify.js'

import './registerServiceWorker'

Vue.config.productionTip = false


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


import axios from 'axios'
import Localbase from 'localbase'

axios.interceptors.response.use(function (response) {
    let r = response.data
    if (r.login) {

        let db = new Localbase('Klara')
        db.collection('auth').doc({ id: 1 }).delete()
        localStorage.clear()

        window.location.replace('/');

    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://localhost/klara/index.php/'
axios.defaults.baseURL = 'https://ims.klarahdiamond.com/api/index.php/'
axios.defaults.headers.common['Auth'] = '';


import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)


new Vue({
    notify,
    router,
    data() {
        return {
            v: '0.12',
            login: false,
            type: '',
            dp: '',
            name: '',
            phone: '',
            user_id: '',
            username: '',
            
            c: {
                // main: 'rgb(31, 81, 255)',
                main: 'royalblue',
                trans: 'rgba(31, 81, 255, 0.2)'
            },
            // imgurl: 'http://localhost/klara/uploads/',
            imgurl: 'https://ims.klarahdiamond.com/api/uploads/',
        }
    },
    render: h => h(App)
}).$mount('#app')
