<template>
    <div class="product">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Product Details
        </div>
        <div class="main">
            <div class="box">
                <div class="image">
                    <img :src="imgurl + image" alt="">
                </div>
                <div class="name">{{product.name}}</div>

                <div class="flex">
                    <div class="label">Cost Price:</div>
                    <div class="value">
                        <vue-numeric read-only currency="$" :precision=2 separator="," v-model="product.cost_price">
                        </vue-numeric>
                    </div>
                </div>
                <div class="flex">
                    <div class="label">Sale Price:</div>
                    <div class="value">
                        <vue-numeric read-only currency="$" :precision=2 separator="," v-model="product.sale_price">
                        </vue-numeric>
                    </div>
                </div>
                
                <div class="flex">
                    <div class="label">Added:</div>
                    <div class="value">{{date}}</div>
                </div>
                <div class="flex">
                    <div class="label">Category:</div>
                    <div class="value">{{product.category.category_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">Vendor:</div>
                    <div class="value">{{product.brand.brand_name}}</div>
                </div>
                <div class="flex">
                    <div class="label">SKU:</div>
                    <div class="value">{{product.sku}}</div>
                </div>
                <div class="flex">
                    <div class="label">Stock:</div>
                    <div class="value">{{product.stock}}</div>
                </div>
                <div class="flex">
                    <div class="label">Quantity Sold:</div>
                    <div class="value">{{product.quantity_sold}}</div>
                </div>
                <div class="flex">
                    <div class="label">Quantity Alert:</div>
                    <div class="value">{{product.quantity_alert}}</div>
                </div>


                <div v-if="features.length > 0">
                    <div class="t">Features</div>
                    <div class="flex" v-for="(f, i) in features" :key="i">
                        <div class="label">{{f.label}}:</div>
                        <div class="value">{{f.value}}</div>
                    </div>
                </div>

                <div v-if="!product.deleted">
                    <div class="option" v-if="!loading">
                        <div class="btn" @click="popup = true">Edit</div>
                        <div class="del" @click="del_pop = true">Delete</div>
                    </div>
                </div>
            </div>

            <div class="title">Product sales</div>

            <loading v-if="loading"/>
            
            <sale v-for="(s, i) in sales" :key="i" :sale="s"/>
        </div>

        
        <product_edit :popup="popup" :product="product" :brands="brands" :categories="categories"
            v-if="popup" @close="popup = false"/>

        <product_delete :popup="del_pop" :product="product"
            v-if="del_pop" @close="del_pop = false" @removed="removed()"/>

    </div>
</template>

<script>
import moment from 'moment'

import back from '../../icons/back.vue'

import product_edit from '../../components/admin/store/product_edit.vue'
import product_delete from '../../components/admin/store/product_delete.vue'

import loading from '../../components/util/loading.vue'
import sale from '../../components/admin/sales/sale.vue'

export default {
    components: {
        back,
        product_edit, product_delete,
        loading,
        sale
    },
    data() {
        return {
            product: {},
            features: [],
            date: '',

            popup: false,
            del_pop: false,

            categories: [],
            brands: [],

            image: '',
            
            loading: true,
            sales: []
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/products/'
        }
    },
    mounted() {

        let p = localStorage.getItem('product')
        this.product = JSON.parse(p)
        
        let images = JSON.parse(this.product['images'])
        this.image = images[0]
        
        this.date = moment(this.product.added).format('Do MMM, yyyy - HH:mm')

        let features = JSON.parse(this.product.features);
        for(let f of features) {
            if (f.label != '') {
                this.features.push(f)
            }
        }

        let form = new FormData()
        form.append('product_id', this.product.product_id)

        this.$http.post('/admin/product/sales', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.sales = r.sales
                this.categories = r.categories
                this.brands = r.brands

                this.loading = false
            }
        })
    },
    methods: {
        
        back() {
            this.$router.go(-1)
        },
        removed() {
            setTimeout(() => {
                this.$router.go(-1)
            }, 800);
        }
    }
}
</script>

<style scoped>
    
    .product {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .left {
        margin-left: auto;
        /* justify-content: flex-end; */
        width: 31px;
        fill: var(--main);
    }
    .del {
        fill: tomato;
    }


    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        margin-top: 25vw;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50vw;
        height: 50vw;
        margin: 0 auto;
        margin-top: calc(-25vw - 20px);
        border-radius: 5px;
        overflow: hidden;
        background-color: #edeef0;
        /* border: 2px solid white; */
    }
    .image img {
        width: 50vw;
    }
    .name {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .price {
        text-align: center;
        margin-top: 5px;
    }
    
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        gap: 15px;
        flex-wrap: wrap;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }
    .t {
        font-weight: 800;
        margin-top: 20px;
    }

    .option {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
        margin-top: 20px;
    }
    .btn {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
    .del {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        background-color: tomato;
        cursor: pointer;
    }

    .title {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 0px;
        padding-top: 30px;
    }
</style>