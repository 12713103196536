<template>
    <div class="page">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Change Password
        </div>
        <div class="main">
            <div class="box">
                <div class="title">Old Password</div>
                <div class="input">
                    <input type="password" v-model="old_password" placeholder="Enter old password">
                </div>
            </div>
            <div class="box">
                <div class="title">New Password</div>
                <div class="input">
                    <input type="password" v-model="new_password" placeholder="Enter New password">
                </div>
                <div class="input">
                    <input type="password" v-model="new_passwors_2" placeholder="Re Enter New password">
                </div>
            </div>

            <btn_loader :height="'38px'" v-if="loading"/>
            <div class="btn" v-else @click="change()">Change Password</div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue'
import btn_loader from '../../components/util/btn_loader.vue'

export default {

    components: {
        back, btn_loader
    },
    data() {
        return {
            old_password: '',

            new_password: '',
            new_passwors_2: '',

            loading: false
        }
    },
    methods: {
        
        back() {
            this.$router.go(-1)
        },
        change() {
            if (this.old_password == '' || this.new_password == '' || this.new_passwors_2 == '') {
                this.empty(); return
            }
            if (this.new_password != this.new_passwors_2) {
                this.mismatch(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('old_password', this.old_password)
            form.append('new_password', this.new_password)

            this.$http.post('/user/settings/password', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    this.$router.go(-1)
                } else {
                    this.incorrect_password()

                    this.loading = false
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter old password and new password'
        },
        mismatch: {
            type: 'warn',
            title: '',
            message: 'New password did not match'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Password Changed'
        },
        incorrect_password: {
            type: 'error',
            title: '',
            message: 'Old password is incorrect'
        }
    }
}
</script>

<style scoped>
    
    .page {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }

    
    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }

    .box {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .title {
        font-weight: 800;
    }
    .input {
        margin-top: 20px;
    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 10px;
        background-color: var(--main);
        border-radius: 5px;
        color: white;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
    }
</style>