<template>
    <div class="settings">
        <div class="head">
            <div>Settings</div>
        </div>
        <div class="main">
            <div class="round">
                <div class="image" v-if="is_image">
                    <img :src="image" alt="">
                </div>
                <div v-else>
                    {{ $root.$data.name[0] }}
                </div>
            </div>
            <div class="name">{{ $root.$data.name }}</div>
            <div class="username">
                @{{ $root.$data.username }}
            </div>

            <div class="box">
                <router-link to="/admin/account_settings">
                    <div class="flex">
                        <account_setting :size="'20px'" :style="{'height': '20px'}"/>
                        Account Setting
                    </div>
                </router-link>
                <router-link to="/admin/change_password">
                    <div class="flex bor_top">
                        <password :size="'20px'" :style="{'height': '20px'}"/>
                        Change Password
                    </div>
                </router-link>

                <div class="flex bor_top" @click="logout()">
                    <logout :size="'20px'" :style="{'height': '20px'}"/>
                    Logout
                </div>
            </div>
        </div>
        <adminMenu/>
    </div>
</template>

<script>
import account_setting from '../../icons/account_setting.vue';
import password from '../../icons/password.vue';
import logout from '../../icons/logout.vue';

import adminMenu from '../../components/menu/admin.vue'


import Localbase from 'localbase'
let db = new Localbase('Klara')

export default {
    components: {
        account_setting, password, logout,
        adminMenu
    },
    data() {
        return {
            is_image: false,
            image: ''
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/dp/'
        }
    },
    mounted() {
        this.is_image = this.$root.$data.dp ? true : false
        this.image = this.imgurl + this.$root.$data.dp
    },
    methods: {
        logout() {
            db.collection('auth').doc({ id: 1 }).delete()
            .then(() => {
                this.$router.push('/')
                this.$router.go()
            })
        }
    }
}
</script>

<style scoped>

    .settings {
        height: 100%;
    }
    .head {
        font-weight: 800;
        padding: 20px;
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 129px);
        overflow: auto;
    }


    .round {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        border: 5px solid #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase;

        color: var(--main);
        margin: 0 auto;
    }
    .image img {
        width: 150px;
    }
    .name {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 800;
        text-align: center;
    }
    .username {
        text-align: center;
        font-size: 14px;
        color: gray;
    }

    .box {
        margin-top: 20px;
        border: 5px;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px;
        transition: .2s;
        font-size: 14px;
        font-weight: 500;
    }
    .flex:active {
        background-color: #edeef0;
    }
    .bor_top {
        border-top: 1px solid #ddd;
    }
</style>