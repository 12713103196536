<template>
    <div class="sales">
        <search_box :placeholder="'Search sales by product name'" v-if="show_search"
            @close="close_search()" @typing="search($event)"/>

        <div class="head">
            <div>Sales</div>
            <div class="icons">
                <router-link to="/user/add_sale">
                    <div class="icon">
                        <add :size="'20px'"/>
                    </div>
                </router-link>
                <div class="icon" @click="show_search = true">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>
        <div class="main">
            <loading v-if="loading"/>
            <div v-else>
                <order v-for="order in orders" 
                    :order="order" :key="order.order_id"/>
            </div>
        </div>
        <userMenu/>
    </div>
</template>

<script>
import moment from 'moment'

import add from '../../icons/add.vue'
import search from '../../icons/search.vue'

import search_box from '../../components/util/search.vue'

import loading from '../../components/util/loading.vue'
import order from '../../components/user/sales/order.vue';

import userMenu from '../../components/menu/user.vue'

export default {
    components: {
        add, search,
        search_box,
        loading, order,
        userMenu
    },
    data() {
        return {
            show_search: false,

            loading: true,

            orders: [],
            all_sales: [],
            sales: []
        }
    },
    mounted() {
        this.$http.post('/user/sales')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.all_sales = r.sales
                this.sales = r.sales

                
                for (let i = 0; i < r.sales.length; i++) {

                    let sale = r.sales[i]
                        
                    if (sale.ordered) {
                        continue
                    }
                    r.sales[i]['ordered'] = true

                    let order = {
                        order_date: moment(sale.sale_created).format('Do MMM, yyyy - HH:mm'),
                        order_title: sale.order_title,
                        order_id: sale.order_id,
                        total_sale_price: parseFloat(sale.sale_sale_price) * parseFloat(sale.quantity),
                        total_cost_price: parseFloat(sale.sale_cost_price) * parseFloat(sale.quantity),
                        products: 1,
                        sales: [sale]
                    }

                    for (let j = 0; j < r.sales.length; j++) {
                        let loop_sale = r.sales[j]

                        if (loop_sale.ordered) {
                            continue
                        }

                        if (sale.order_id == loop_sale.order_id) {
                            
                            order.total_sale_price += parseFloat(loop_sale.sale_sale_price) * parseFloat(loop_sale.quantity)
                            order.total_cost_price += parseFloat(loop_sale.sale_cost_price) * parseFloat(loop_sale.quantity)
                            order.products += 1
                            order.sales.push(loop_sale)

                            r.sales[j]['ordered'] = true
                        }
                    }

                    this.orders.push(order)
                }
            }
            this.loading = false
        })
    },
    methods: {
        
        search(e) {
            this.sales = []
            for (let x of this.all_sales) {
                if (x.product.name.toLowerCase().includes(e.toLowerCase())) {
                    this.sales.push(x)
                }
            }
        },
        close_search() {
            this.show_search = false

            this.sales = this.all_sales
        },
    }
}
</script>

<style scoped>
    

    .sales {
        height: 100%;
    }
    .head {
        font-weight: 800;
        padding-left: 20px;
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icons {
        display: flex;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 129px);
        overflow: auto;
    }
</style>