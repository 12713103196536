<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size"  viewBox="0 -960 960 960">
            <path d="M256-213.847 213.847-256l224-224-224-224L256-746.153l224 224 224-224L746.153-704l-224 
            224 224 224L704-213.847l-224-224-224 224Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>