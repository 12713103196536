<template>
    <div class="sales">
        <searchBox v-if="show_search" :placeholder="'Search sales by product name'"
            @close="close_search()" @typing="search($event)"/>

        <div class="head">
            <div>Sales</div>
            <div class="icons">
                <div class="icon">
                    <chart :size="'20px'"/>
                </div>
                <div class="icon">
                    <add :size="'20px'"/>
                </div>
                <div class="icon" @click="show_search = true">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="l" v-if="loading">
                <loading/>
            </div>
            <div v-else v-for="(month, i) in months" :key="i">
                <div class="stat">
                    <div class="month">
                        {{month.title}} 

                        <div class="arr">
                            <arrow :size="'30px'"/>
                        </div>
                    </div>

                    <div>
                        <div class="amt">
                            <vue-numeric read-only currency="$" :precision=2 separator="," v-model="month.total_sale_price">
                            </vue-numeric>
                        </div>
                        <div class="profit">
                            Profit 
                            <b>
                                <vue-numeric read-only currency="$" :precision=2 separator="," 
                                v-model="month.profit"> </vue-numeric>
                            </b>
                        </div>
                    </div>
                </div>
                
                <order v-for="(order, i) in month.orders" :key="i" :order="order"/>
            </div>
        </div>

        <adminMenu/>
    </div>
</template>

<script>
import moment from 'moment'

import searchBox from '../../components/util/search.vue'

import arrow from '../../icons/arrow.vue'
import chart from '../../icons/chart.vue'
import add from '../../icons/add.vue'
import search from '../../icons/search.vue'

import loading from '../../components/util/loading.vue'

import order from '../../components/admin/sales/order.vue'
// import sale from '../../components/admin/sales/sale.vue'


import adminMenu from '../../components/menu/admin.vue'

export default {
    components: {
        searchBox,
        chart, add, search,
        arrow, order,
        loading,
        adminMenu
    },
    data() {
        return {
            show_search: false,

            loading: true,
            all_months: [],
            months: [],
            sales: []
        }
    },
    mounted() {
        this.$http.post('/admin/sales')
        .then(data => {
            let r = data.data
            if (r.success) {
                if (r.sales.length == 0) {
                    this.loading = false
                    return
                }
                let current_year = moment().format('YYYY')
                

                let months = []
                let month = {
                    title: '',
                    sales: [],
                    orders: []
                }

                let last_known_year = moment().format('YYYY')
                let last_knwon_month = moment().format('MMMM')

                for (let x of r.sales) {

                    let sale_year = moment(x.sale_created).format('YYYY')
                    let sale_month = moment(x.sale_created).format('MMMM')

                    console.log(sale_year, sale_month)
                    console.log(last_known_year, last_knwon_month)

                    x['ordered'] = false

                    if (last_known_year == sale_year && last_knwon_month == sale_month) {
                        
                        month['title'] = `${sale_month} ${sale_year == current_year ? '' : sale_year}`
                        month.sales.push(x)
                    } else {
                        
                        if (month.sales.length > 0) { 
                            months.push(month)
                        }

                        month = {
                            title: `${sale_month} ${sale_year == current_year ? '' : sale_year}`,
                            sales: [x],
                            orders: []
                        }
                    }
                    
                    last_known_year = moment(x.created).format('YYYY')
                    last_knwon_month = moment(x.created).format('MMMM')
                }

                months.push(month)

                let ordered_months = []


                for(let m of months) {
                    for (let i = 0; i < m.sales.length; i++) {
                        let sale = m.sales[i]
                        
                        if (sale.ordered) {
                            continue
                        }
                        m.sales[i].ordered = true

                        let order = {
                            order_date: moment(sale.sale_created).format('Do MMM, yyyy - HH:mm'),
                            order_title: sale.order_title,
                            order_id: sale.order_id,
                            total_sale_price: parseFloat(sale.sale_sale_price) * parseFloat(sale.quantity),
                            total_cost_price: parseFloat(sale.sale_cost_price) * parseFloat(sale.quantity),
                            products: 1,
                            sales: [sale]
                        }

                        for (let j = 0; j < m.sales.length; j++) {
                            let loop_sale = m.sales[j]
                        
                            if (loop_sale.ordered) {
                                continue
                            }

                            if (sale.order_id == loop_sale.order_id) {
                                
                                order.total_sale_price += parseFloat(loop_sale.sale_sale_price) * parseFloat(loop_sale.quantity)
                                order.total_cost_price += parseFloat(loop_sale.sale_cost_price) * parseFloat(loop_sale.quantity)
                                order.products += 1
                                order.sales.push(loop_sale)

                                m.sales[j].ordered = true
                            }
                        }

                        m.orders.push(order)
                    }
                    ordered_months.push(m)
                }

                for (let y of ordered_months) {
                    let total_sale_price = 0
                    let total_cost_price = 0

                    for (let order of y.orders) {
                        total_sale_price += order.total_sale_price
                        total_cost_price += order.total_cost_price
                    }

                    y['total_sale_price'] = total_sale_price
                    y['total_cost_price'] = total_cost_price
                    y['profit'] = total_sale_price - total_cost_price

                    this.months.push(y)
                }

                let mmmmm = JSON.stringify(this.months)
                this.all_months = JSON.parse(mmmmm)

            }


            this.loading = false
        })
    },
    methods: {
        
        search(e) {
            this.months = []

            for (var x of this.all_months) {

                var new_month = JSON.parse(JSON.stringify(x))
                new_month['sales'] = []

                for (var s of x.sales) {
                    if (s.product.name.toLowerCase().includes(e.toLowerCase())) {
                        new_month.sales.push(s)
                    }
                }

                this.months.push(new_month)
            }

        },
        close_search() {
            this.months = this.all_months
            this.show_search = false
        }
    }
}
</script>

<style scoped>

    .sales {
        height: 100%;
    }
    .head {
        font-weight: 800;
        padding-left: 20px;
        font-size: 18px;
        border-bottom: 1px solid #ddd;
        /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icons {
        display: flex;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        padding-top: 0px;
        height: calc(100% - 130px);
        overflow: auto;
        position: relative;
    }
    .l {
        padding: 20px;
    }


    .stat {
        position: sticky;
        top: 0px;

        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        margin: 0px -20px;
        /* margin-top: -10px; */
        margin-bottom: 20px;
        font-size: 14px;
        
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .month {
        display: flex;
        align-items: center;
        transition: .2s;
        padding: 0px 10px;
        margin-left: -10px;
        border-radius: 5px;
    }
    .month:active {
        background-color: #edeef0;
    }
    .arr {
        transform: rotate(90deg);
    }
    .amt {
        font-weight: 800;
    }
    .profit {
        font-size: 12px;
    }
</style>