<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Edit Vendor</div>
                <div class="main">
                    <div class="input">
                        <div class="label">Name</div>
                        <input type="text" placeholder="Enter vendor name" v-model="name">
                    </div>

                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="btn" v-else @click="save()">Save</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import btn_loader from '../../util/btn_loader.vue';

export default {
    components: {
        btn_loader
    },
    props: ['brand'],
    data() {
        return {
            pop: false,
            name: '',
            loading: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {

        this.name = this.brand.name

        setTimeout(() => {
            this.pop = true
        }, 100);
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },

        save() {
            if (this.name == '') {
                this.empty(); return
            }

            let form = new FormData()
            form.append('name', this.name)
            form.append('brand_id', this.brand.brand_id)

            this.$http.post('/admin/brand/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.closepop()

                    this.$emit('updated', {
                        'name': this.name
                    })
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter vendor name'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Vendor updated'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        height: fit-content;
        box-sizing: border-box;
        overflow: auto;
    }


    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        /* color: gray; */
        font-size: 14px;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
    }

    .text {
        margin-bottom: 20px;
    }
    .grid {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }
    .click {
        padding: 10px;
        background-color: var(--trans);
        color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        margin-bottom: 20px;
        font-size: 14px;
    }



        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: calc(-153px - 60px);
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>