<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M398-641q-63 0-106.5-43.5T248-791q0-63 43.5-106.5T398-941q63 0 106.5 43.5T548-791q0 63-43.5 
            106.5T398-641Zm210 0q-13 0-25-1t-24-6q27-29 38-66.5t11-76.5q0-39-12-76t-37-67q12-4 24-5.5t25-1.5q63 
            0 106.5 43.5T758-791q0 63-43.5 106.5T608-641Zm162 471q-79 0-134.5-55.5T580-360q0-79 55.5-134.5T770-550q79 
            0 134.5 55.5T960-360q0 79-55.5 134.5T770-170Zm-38-108 134-134-21-21-113 113-56-57-21 21 77 
            78ZM78-320v-94q0-35 18-63.5t50-42.5q60-26 123-43t129-17q51 0 101.5 10.5T598-541q-45 42-64 
            100.5T524-320H78Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>