<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Edit product</div>
                <div class="main">
                    <div class="input">
                        <div class="label">Category</div>
                        <select v-model="category_id">
                            <option value="" selected disabled>Select Category</option>
                            <option v-for="(c, i) in categories" :key="i" :value="c.category_id">
                                {{c.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">Vendor</div>
                        <select v-model="brand_id">
                            <option value="" selected disabled>Select Vendor</option>
                            <option v-for="(b, i) in brands" :key="i" :value="b.brand_id">
                                {{b.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">Name</div>
                        <input type="text" placeholder="Enter product name" v-model="name">
                    </div>
                    <div class="input">
                        <div class="label">Cost Price</div>
                        <input type="number" placeholder="Enter cost price" v-model="cost_price">
                    </div>
                    <div class="input">
                        <div class="label">Sale Price</div>
                        <input type="number" placeholder="Enter sale price" v-model="sale_price">
                    </div>
                    <div class="input">
                        <div class="label">Stock</div>
                        <input type="number" placeholder="Enter stock" v-model="stock">
                    </div>
                    <div class="input">
                        <div class="label">Quantity Alert</div>
                        <input type="number" placeholder="Enter quantity alert" v-model="quantity_alert">
                    </div>
                    <div class="input">
                        <div class="label">SKU</div>
                        <input type="text" placeholder="Enter sku" v-model="sku">
                    </div>

                    <div class="text">Features</div>
                    <div class="grid" v-for="(f, i) in features" :key="i">
                        <div class="input">
                            <div class="label">Label</div>
                            <input type="text" placeholder="" v-model="features[i].label">
                        </div>
                        <div class="input">
                            <div class="label">Value</div>
                            <input type="text" placeholder="" v-model="features[i].value">
                        </div>
                    </div>
                    <div class="click" @click="add_feature()">Add Features</div>


                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="btn" v-else @click="save()">Save</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import btn_loader from '../../util/btn_loader.vue'
export default {
    components: {
        btn_loader
    },
    props: ['popup', 'product', 'categories', 'brands'],
    data() {
        return {
            pop: false,

            category_id: '',
            brand_id: '',
            name: '',
            // image: '',
            cost_price: '',
            sale_price: '',
            // quantity_sold: '',
            quantity_alert: '',
            stock: '',
            sku: '',
            features: [],

            loading: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {

        this.category_id = this.product.category.category_id
        this.brand_id = this.product.brand.brand_id
        this.name = this.product.name
        this.cost_price = this.product.cost_price
        this.sale_price = this.product.sale_price
        this.quantity_alert = this.product.quantity_alert
        this.stock = this.product.stock
        this.sku = this.product.sku
        
        let features = JSON.parse(this.product.features);
        for(let f of features) {
            if (f.label != '') {
                this.features.push(f)
            }
        }
        if (this.features.length == 0) {
            this.features = [
                {
                    'label': '',
                    'value': ''
                }
            ]
        }
        
        setTimeout(() => {
            this.pop = true
        }, 100);
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },
        add_feature() {
            this.features.push({'label': '', 'value': ''})
        },
        save() {
            
            if ( this.category_id == '' || this.brand_id == '' || this.name == '' ||
                this.cost_price == '' || this.sale_price == '' || this.quantity_alert == '' ||
                this.stock == '' || this.sku == '') {
                this.empty(); return
            }
            this.loading = true
            
            let form = new FormData()
            form.append('product_id', this.product.product_id)
            form.append('category_id', this.category_id)
            form.append('brand_id', this.brand_id)
            form.append('name', this.name)
            form.append('cost_price', this.cost_price)
            form.append('sale_price', this.sale_price)
            form.append('quantity_alert', this.quantity_alert)
            form.append('stock', this.stock)
            form.append('sku', this.sku)
            form.append('features', JSON.stringify(this.features))

            this.$http.post('/admin/product/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.closepop()

                    this.product.category = r.category
                    this.product.brand = r.brand
                    this.product.name = this.name
                    this.product.cost_price = this.cost_price
                    this.product.sale_price = this.sale_price
                    this.product.quantity_alert = this.quantity_alert
                    this.product.stock = this.stock
                    this.product.sku = this.sku
                    this.product.features = JSON.stringify(this.features)

                    localStorage.setItem('product', JSON.stringify(this.product))

                    this.$emit('updated', this.product)
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        height: 70vh;
        box-sizing: border-box;
        overflow: auto;
    }

    .upload {
        height: 300px;
        background-color: #edeef0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;
    }
    .upload_text {
        font-size: 14px;
    }

    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        /* color: gray; */
        font-size: 14px;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
    }

    .text {
        margin-bottom: 20px;
    }
    .grid {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
    }
    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }
    .click {
        padding: 10px;
        background-color: var(--trans);
        color: var(--main);
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        margin-bottom: 20px;
        font-size: 14px;
    }



        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: calc(-50vh - 60px);
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>