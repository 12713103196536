<template>
    <div class="product">
        <div class="head">
            <div class="image">
                <img :src="imgurl + image" alt="">
            </div>
            <div>
                <div class="name">{{product.name}}</div>
                <div class="price">
                    <vue-numeric read-only currency="$" :precision=2 separator="," 
                        v-model="product.sale_price">
                    </vue-numeric>
                </div>
            </div>
            <div class="remove" @click="$emit('remove', i)">
                <close :size="'25px'"/>
            </div>
        </div>
        <div class="main">
            <div class="text">Quantity:</div>
            <div class="flex">
                <div class="click" @click="minus()">
                    <minus :size="'15px'" :style="{'height': '15px'}"/>
                </div>
                <input type="number" v-model="quantity" min="1" @input="emit()">
                <div class="click" @click="plus()">
                    <add :size="'15px'" :style="{'height': '15px'}"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import close from '../../../icons/close.vue'

import minus from '../../../icons/minus.vue'
import add from '../../../icons/add.vue'

export default {
    components: {
        close,
        minus, add
    },
    props: ['product', 'i'],
    data() {
        return {
            image: '',

            quantity: 1
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/products/'
        }
    },
    mounted() {
        let images = JSON.parse(this.product['images'])
        this.image = images[0]
    },
    methods: {
        plus() {
            this.quantity++

            this.emit()
        },
        minus() {
            if (this.quantity == 1) { return }
            this.quantity--
            
            this.emit()
        },
        emit() {
            this.$emit('new_quantity', {
                'i': this.i,
                'quantity': parseInt(this.quantity)
            })
        }
    }
}
</script>

<style scoped>
    
    .product {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .head {
        display: flex;
        align-items: center;
        padding: 15px;
        gap: 15px;
        border-bottom: 1px solid #ddd;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    .image img {
        width: 40px;
    }

    .name {
        font-weight: 600;
    }
    .price {
        display: flex;
        gap: 5px;
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
    .remove {
        margin-left: auto;
    }


    .main {
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 14px;
    }
    .flex {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .click {
        width: 25px;
        height: 25px;
        background-color: #edeef0;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    input {
        width: 50px;
        border: none;
    }
</style>