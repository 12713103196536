<template>
    <div class="search">
        <input type="text" :placeholder="placeholder" ref="input" 
            @keyup="typing()" v-model="name">

        <div class="icon" @click="$emit('close')">
            <close :size="'20px'"/>
        </div>
    </div>
</template>

<script>
import close from '../../icons/close.vue'

export default {
    props: ['placeholder'],
    components: {
        close
    },
    mounted() {
        this.$refs.input.focus()
    },
    data() {
        return {
            name: ''
        }
    },
    methods: {
        typing() {
            this.$emit('typing', this.name)
        }
    }
}
</script>

<style scoped>
    .search {
        background-color: white;
        display: grid;
        align-items: center;
        grid-template-columns: auto max-content;

        position: fixed;
        z-index: 10;
        top: 0px;
        left: 0px;
        width: 100%;
    }
    input {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        border: none;
    }
    .icon {
        width: 61px;
        height: 61px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }

</style>