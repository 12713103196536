<template>
    <div class="menu">
        <div class="item" :class="[active == 'dashboard' ? 'a' : '']" @click="go_to('')">
            <div class="icon">
                <dashboard :size="'20px'"/>
            </div>
            <div class="label">Dashboard</div>
        </div>
        <div class="item" :class="[active == 'store' ? 'a' : '']" @click="go_to('store')">
            <div class="icon">
                <store :size="'20px'"/>
            </div>
            <div class="label">Store</div>
        </div>
        <div class="item" :class="[active == 'sales' ? 'a' : '']" @click="go_to('sales')">
            <div class="icon">
                <sales :size="'20px'"/>
            </div>
            <div class="label">Sales</div>
        </div>
        <div class="item" :class="[active == 'staffs' ? 'a' : '']" @click="go_to('staffs')">
            <div class="icon">
                <staff :size="'20px'"/>
            </div>
            <div class="label">Staff</div>
        </div>
        <div class="item" :class="[active == 'settings' ? 'a' : '']" @click="go_to('settings')">
            <div class="icon">
                <settings :size="'20px'"/>
            </div>
            <div class="label">Settings</div>
        </div>
    </div>
</template>

<script>
import dashboard from '../../icons/dashboard.vue'
import store from '../../icons/store.vue'
import sales from '../../icons/sales.vue'
import staff from '../../icons/staff.vue'
import settings from '../../icons/settings.vue'

export default {
    components: {
        dashboard, store, sales, staff, settings
    },
    data() {
        return {
            active: ''
        }
    },
    mounted() {
        setTimeout(() => {
            this.active = this.$route.name
        }, 10);
    },
    methods: {
        go_to(where) {
            this.$router.push('/admin/' + where)
        }
    }
}
</script>

<style scoped>
    .menu {
        position: relative;
        z-index: 2;
        
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        align-items: center;
        /* border-top: 1px solid #ddd; */
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: hidden;

        background-color: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    .item {
        text-align: center;
        padding: 15px 0px;
        fill: #555;
        color: gray;
        transition: .2s;
        overflow: hidden;
    }
    .item:active {
        background-color: #edeef0;
        fill: var(--main);
    }
    .icon {
        transition: .2s;
    }
    .label {
        font-size: 10px;
        font-weight: 500;
    }
    .a {
        fill: var(--main);
        color: var(--main);
    }
    .a .icon {
        position: relative;
        /* z-index: 1; */
        transform: scale(1.5);
    }
    .a .label {
        font-weight: 600;
    }
</style>