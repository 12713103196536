<template>
    <div class="login">
        <div class="head">
            <img src="../assets/images/logo_white.svg"/>
            <div class="title">Klara Diamond</div>
        </div>
        <div class="form">
            <div class="t">Login</div>
            <div class="small">Enter your username and password to login</div>

            <div class="input">
                <div class="label" v-if="username != ''">Username</div>
                <input type="text" placeholder="Username" v-model="username">
            </div>
            <div class="input">
                <div class="label" v-if="password != ''">Password</div>
                <input type="password" placeholder="Password" v-model="password">
            </div>
            <btn_loader :height="'43px'" v-if="loading"/>
            <div class="btn" v-else @click="login()">Login</div>
        </div>
        <div class="bottom">
            Inventory Management System v{{ $root.$data.v }}
        </div>
    </div>
</template>

<script>
import btn_loader from '../components/util/btn_loader.vue';

import Localbase from 'localbase'

export default {
    components: {
        btn_loader
    },
    data() {
        return {
            db: new Localbase('Klara'),
            username: '',
            password: '',
            loading: false
        }
    },
    methods: {
        login() {
            if (this.username == '' || this.password == '') {
                this.empty(); return
            }
            this.loading = true

            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'type': r.type,
                        'dp': r.dp,
                        'phone': r.phone,
                        'name': r.name,
                        'user_id': r.user_id,
                        'username': this.username,
                    }).then( () => {
                        this.$root.$data.login = true
                        this.$root.$data.type = r.type
                        this.$root.$data.dp = r.dp
                        this.$root.$data.name = r.name
                        this.$root.$data.phone = r.phone
                        this.$root.$data.username = r.username
                        
                        this.$http.defaults.headers.common['Auth'] = r.session

                        if (r.type == 'admin') {
                            setTimeout(() => {
                                this.$router.push('/admin')
                            }, 1000);
                        } if (r.type == 'user') {
                            setTimeout(() => {
                                this.$router.push('/user')
                            }, 1000);
                        }
                    })
                } else {
                    if (r.username) {
                        this.username_err()
                    }
                    if (r.password) {
                        this.password_err()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter your username and password'
        },
        username_err: {
            type: 'warn',
            title: 'Username',
            message: 'Username not found'
        },
        password_err: {
            type: 'warn',
            title: 'Password',
            message: 'Incorrect password'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'You are now logged in'
        }
    }
}
</script>

<style scoped>
    .head {
        padding: 50px 20px;
        /* background-color: var(--main); */
        color: white;
        text-align: center;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;

        background: url('../assets/bg/circle_bg.svg');
        background-size: cover;
        background-position: center;
    }
    .head img {
        width: 50px;
    }
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
    }


    .form {
        padding: 30px;
        padding-top: 50px;
    }
    .t {
        font-weight: 800;
        font-size: 18px;
    }
    .small {
        font-size: 12px;
        color: gray;
        margin-bottom: 30px;
        margin-top: 5px;
    }


    
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        padding: 0px 5px;
        background-color: white;
        animation: .2s up;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -10px;
        }
    }
    input, select {
        width: 100%;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        box-sizing: border-box;
        outline: none;
        background-color: white;
    }
    input:-webkit-autofill { 
        -webkit-background-clip: text;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .btn {
        /* background-color: var(--main); */
        /* background: linear-gradient(to left, var(--main), #1e45cf); */
        color: white;
        cursor: pointer;
        padding: 12px 30px;
        border-radius: 5px;
        font-weight: 500;
        /* margin-top: 20px; */
        text-align: center;
    }


    .bottom {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 20px;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        background-color: #edeef0;
        font-size: 14px;
        font-weight: 700;
    }
</style>