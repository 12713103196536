<template>
    <div class="brand">
        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            Vendor Details
        </div>
        <div class="main">
            <div class="box">
                <div class="round">
                    <img :src="imgurl + brand.image" v-if="brand.image">
                    <div class="bold" v-else>{{brand.name[0]}}</div>
                </div>
                <div class="name">{{brand.name}}</div>
                
                <div class="flex">
                    <div class="label">Products:</div>
                    <div class="value">{{brand.products}}</div>
                </div>
                <div class="flex">
                    <div class="label">Sales:</div>
                    <div class="value">{{brand.sales}}</div>
                </div>
                <div class="flex">
                    <div class="label">Added:</div>
                    <div class="value">{{date}}</div>
                </div>

                <div class="option">
                    <div class="btn" @click="popup = true">Edit</div>
                    <div class="del" @click="del_pop = true">Delete</div>
                </div>
            </div>
            
            <div class="title">Products</div>

            <loading v-if="loading"/>
            <div v-else>
                <product v-for="(product, i) in products" :key="i" :product="product" 
                    :brands="brands" :categories="category"/>
            </div>
        </div>

        <brand_edit :brand="brand" v-if="popup"
            @close="popup = false" @updated="updated($event)"/>

        <brand_delete :brand="brand" v-if="del_pop"
            @close="del_pop = false" @removed="removed()"/>
    </div>
</template>

<script>
import moment from 'moment'

import back from '../../icons/back.vue'

import loading from '../../components/util/loading.vue'

import product from '../../components/admin/store/product.vue'

import brand_edit from '../../components/admin/store/brand_edit.vue'
import brand_delete from '../../components/admin/store/brand_delete.vue'

export default {
    components: {
        back,
        loading, 
        product,
        brand_edit, brand_delete
    },
    data() {
        return {
            
            brand: {},
            date: '',
            
            loading: false,
            products: [],
            categories: [],
            brands: [],

            popup: false,
            del_pop: false
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/product_brands/'
        }
    },
    mounted() {
        let c = localStorage.getItem('brand')
        this.brand = JSON.parse(c)

        this.date = moment(this.brand.created).format('Do MMM, yyyy - HH:mm')

        let form = new FormData()
        form.append('brand_id', this.brand.brand_id)

        this.$http.post('/admin/brand/products', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.products = r.products
                this.categories = r.categories
                this.brands = r.brands
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        updated(e) {
            this.brand.name = e.name

            localStorage.setItem('brand', JSON.stringify(this.brand))
        },
        removed() {
            setTimeout(() => {
                this.$router.go(-1)
            }, 800);
        }
    }
}
</script>

<style scoped>
    
    .category {
        height: 100%;
    }
    .head {
        font-weight: 800;
        font-size: 18px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }


    .main {
        height: calc(100vh - 62px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        margin-top: 35px;
    }
    .round {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-top: -45px;
        border-radius: 50%;
        background-color: #edeef0;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        border: 5px solid white;
    }
    .round img {
        width: 60px;
    }
    .bold {
        font-weight: 800;
        font-size: 18px;
        color: var(--main);
    }

    .name {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        gap: 15px;
        flex-wrap: wrap;
    }
    .label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }

    .option {
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        gap: 20px;
        margin-top: 20px;
    }
    .btn {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
    .del {
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        background-color: tomato;
        cursor: pointer;
    }


    .title {
        font-size: 18px;
        font-weight: 800;
        padding: 20px 0px;
        padding-top: 30px;
    }
</style>