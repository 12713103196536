<template>
    <div class="store">
        <searchBox v-if="show_search" :placeholder="'Search products, categories and brands'"
            @close="close_search()" @typing="search($event)"/>

        <div class="head">
            Store
            <div class="icon" @click="show_search = true">
                <search :size="'20px'"/>
            </div>
        </div>
        <div class="main" v-if="loading">
            <loading />
        </div>
        <div class="main" v-else>
            <category :ca="categories"/>
            <brand :ba="brands"/>

            <products :products="products" :category="categories" :brands="brands"
                @new_product="new_product($event)"/>
        </div>

        <adminMenu/>
    </div>
</template>

<script>
import search from '../../icons/search.vue'

import searchBox from '../../components/util/search.vue'

import loading from '../../components/util/loading.vue'

import category from '../../components/admin/store/category.vue'
import brand from '../../components/admin/store/brand.vue'
import products from '../../components/admin/store/products.vue'


import adminMenu from '../../components/menu/admin.vue'

export default {
    components: {
        search,
        searchBox,
        loading,
        category, brand,
        products, adminMenu
    },
    data() {
        return {
            loading: true,

            show_search: '',

            products: [],
            categories: [],
            brands: [],

            all_products: [],
            all_categories: [],
            all_brands: []
        }
    },
    mounted() {
        this.$http.post('/admin/store')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.products = r.products
                this.categories = r.categories
                this.brands = r.brands

                this.all_products = r.products
                this.all_categories = r.categories
                this.all_brands = r.brands

                this.loading = false
            }
        })
    },
    methods: {
        search(e) {
            this.products = []
            for (let x of this.all_products) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.products.push(x)
                }
            }

            this.categories = []
            for (let x of this.all_categories) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.categories.push(x)
                }
            }

            this.brands = []
            for (let x of this.all_brands) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.brands.push(x)
                }
            }
        },
        close_search() {
            this.show_search = false

            this.products = this.all_products
            this.categories = this.all_categories
            this.brands = this.all_brands

        },
        new_category(e) {
            this.categories.unshift(e)
        },
        new_brand(e) {
            this.brands.unshift(e)
        },
        new_product(e) {
            for (let i = 0; i < this.categories.length; i++) {
                if (e.category.name == this.categories[i]['name'] ) {
                    let c = this.categories[i]
                    c.products = parseInt(c.products)
                    c.products++
                    this.categories[i] = c
                }
            }
            for (let i = 0; i < this.brands.length; i++) {
                if (e.brand.name == this.brands[i]['name'] ) {
                    let b = this.brands[i]
                    b.products = parseInt(b.products)
                    b.products++
                    this.brands[i] = b
                }
            }

            this.products.unshift(e)
        }
    }
}
</script>

<style scoped>
    .store {
        height: 100%;
    }
    .head {
        font-weight: 800;
        padding-left: 20px;
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 129px);
        overflow: auto;
    }
</style>