<template>
    <div class="a_sale">

        <div class="head">
            <div class="icon" @click="back()">
                <back :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            <div>New Sale</div>
        </div>
        <div class="main">
            <div class="box">
                <div class="title">Order</div>
                <div class="input">
                    <div class="label">Order Title</div>
                    <input type="text" placeholder="Enter order title" v-model="order_title">
                    
                    <div class="des"> Enter order name, eg Knotless Braid </div>
                </div>
            </div>
            <div class="box" v-if="is_customer">
                <div class="click" @click="remove_customer()">
                    <close :size="'25px'"/>
                </div>
                <div class="title">Customer</div>
                <div class="flex">
                    <div class="flex_label">Name:</div>
                    <div class="value">{{ customer.name }}</div>
                </div>
                <div class="flex">
                    <div class="flex_label">Phone:</div>
                    <div class="value">{{ customer.phone }}</div>
                </div>
                <div class="flex">
                    <div class="flex_label">Purchases:</div>
                    <div class="value">{{ customer.purchases }}</div>
                </div>
            </div>
            <div class="box" v-else>
                <div class="title">Customer</div>
                <div class="input">
                    <div class="label">Phone Number</div>
                    <input type="text" placeholder="Enter customer phone number" v-model="phone">
                </div>

                <btn_loader :height="'42px'" v-if="getting"/>
                <div class="btn" v-else @click="get_customer()">Get Customer</div>
            </div>
            <div class="products">
                <div class="title product_title">Products</div>
                <product v-for="(product, i) in selected_products" :key="product.product_id" 
                    :product="product" :i="i" 
                    @remove="remove_product($event)" @new_quantity="new_quantity($event)"/>

                <div class="btn" @click="show_add_product = true">Add Products</div>
            </div>
            <div class="box box2">
                <div class="title">Payment</div>
                <div class="input">
                    <div class="label">Payment Type</div>
                    <select v-model="payment_type">
                        <option value="" selected disabled> Choose payment type </option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Online Payment">Online Payment</option>
                        <!-- <option value="Cash">Cash</option> -->
                    </select>
                </div>
                <div class="input" v-if="payment_type == 'Bank Transfer'">
                    <div class="label">Sender Name</div>
                    <input type="text" v-model="sender_name" placeholder="Enter sender name">
                </div>
            </div>
        </div>
        <div class="add">
            <div class="text">
                Total Amount:  

                <b>
                    <vue-numeric read-only currency="$" :precision=2 separator="," 
                        v-model="total">
                    </vue-numeric>
                </b>
            </div>
            <btn_loader :height="'42px'" v-if="adding"/>
            <div class="btn" v-else @click="add_sale()">Add Sale</div>
        </div>

        <add_customer v-if="show_add" @new_customer="new_customer($event)" 
            @close="show_add = false"/>
        
        <add_product v-if="show_add_product" :products="products"
            @close="show_add_product = false; submitted()" @submitted="submitted()"/>
    </div>
</template>

<script>

import back from '../../icons/back.vue'
import close from '../../icons/close.vue'

import btn_loader from '../../components/util/btn_loader.vue'

import product from '../../components/user/sales/product.vue'

import add_customer from '../../components/user/sales/add_customer.vue'
import add_product from '../../components/user/sales/add_product.vue'

export default {
    components: {
        back, close,
        btn_loader,
        product,
        add_customer,
        add_product
    },
    data() {
        return {
            order_title: '',
            phone: '',
            getting: false,
            is_customer: false,
            customer: {},

            loading: true,
            all_products: [],
            products: [],

            selected_products: [],

            payment_type: '',
            sender_name: '',

            total: 0,
            adding: false,

            show_add: false,
            show_add_product: false
        }
    },
    mounted() {
        this.$http.post('/user/products')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.all_products = r.products
                this.products = r.products

                this.loading = false
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        remove_customer() {
            this.customer = {}
            this.is_customer = false
        },
        new_customer(e) {
            this.customer = e
            this.is_customer = true
        },
        get_customer() {
            if (this.phone == '') {
                this.empty(); return
            }
            this.getting = true

            let form = new FormData()
            form.append('phone', this.phone)

            this.$http.post('/user/customer/get', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    if (!r.customer) {
                        this.add_customer_msg()
                        this.show_add = true
                    } else {
                        this.is_customer = true
                        this.customer = r.customer
                    }
                }
                this.getting = false
            })
        },
        submitted() {
            this.selected_products = []

            for (let x of this.products) {
                if (x.selected) {
                    this.selected_products.push(x)
                }
            }

            this.calc_total()
        },
        remove_product(i) {
            let removed = this.selected_products[i]
            this.selected_products.splice(i, 1)

            this.products = []
            for (let y of this.all_products) {
                if (y.product_id == removed.product_id) {
                    y.selected = false
                }
                this.products.push(y)
            }

            this.calc_total()
        },
        new_quantity(e) {
            this.selected_products[e.i].quantity = e.quantity

            this.calc_total()
        },
        calc_total() {
            this.total = 0

            for (let x of this.selected_products) {
                this.total += parseFloat(x.sale_price) * x.quantity
            }
        },
        add_sale() {
            if (this.order_title == '') {
                this.order_title_err(); return
            }
            if (!this.is_customer) {
                this.customer_err(); return
            }
            if (this.selected_products.length == 0) {
                this.products_err(); return
            }
            if (this.payment_type == '') {
                this.payment_type_err(); return
            }
            if (this.payment_type == 'Bank Transfer') {
                if (this.sender_name == '') {
                    this.sender_name_err(); return
                }
            }
            this.adding = true

            let form = new FormData()
            form.append('order_title', this.order_title)
            form.append('customer_id', this.customer.customer_id)
            form.append('products', JSON.stringify(this.selected_products))
            form.append('payment_type', this.payment_type)
            form.append('sender_name', this.sender_name)

            this.$http.post('/user/sale/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$router.go(-1)
                }   
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter customer phone number'
        },
        add_customer_msg: {
            type: 'info',
            title: '',
            message: 'Add new customer'
        },

        order_title_err: {
            type: 'warn',
            title: '',
            message: 'Enter order title'
        },
        customer_err: {
            type: 'warn',
            title: '',
            message: 'Add customer to this sale'
        },
        products_err: {
            type: 'warn',
            title: '',
            message: 'Add products to this sale'
        },
        payment_type_err: {
            type: 'warn',
            title: '',
            message: 'Select payment type'
        },
        sender_name_err: {
            type: 'warn',
            title: '',
            message: 'Enter sender name'
        },

        success: {
            type: 'success',
            title: '',
            message: 'Sales added'
        }
    }
}
</script>

<style scoped>
    
    .a_sale {
        height: 100%;
    }
    .head {
        font-weight: 800;
        /* padding: 20px; */
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        /* justify-content: space-between; */
    }
    .icons {
        display: flex;
    }
    .icon {
        width: 61px;
        height: 61px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 171px);
        overflow: auto;
    }
    .title {
        font-weight: 600;
    }
    .box {
        margin-bottom: 20px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        position: relative;
    }
    .click {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }
    .flex_label {
        font-size: 14px;
        color: gray;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }


    .input {
        position: relative;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        left: 10px;
        top: -10px;
        padding: 0px 5px;
        background-color: white;
        font-size: 14px;
        color: gray;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .des {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: -20px;
        /* text-align: right; */
    }
    .btn {
        padding: 12px;
        text-align: center;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        font-size: 14px;
    }
    .product_title {
        margin-bottom: 20px;
    }


    .box2 {
        margin-top: 20px;
    }


    .text {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .add {
        box-shadow: rgba(100, 100, 111, 0.2) 0px -7px 29px 0px;
        background-color: white;
        padding: 20px;
        position: relative;
    }
</style>