import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


import Admin from '../views/Admin.vue'
import adminDashboard from '../views/admin/dashboard.vue'

import adminStore from '../views/admin/store.vue'
import adminCategory from '../views/admin/category.vue'
import adminBrand from '../views/admin/brand.vue'
import adminProduct from '../views/admin/product.vue'
import adminDeletedProducts from '../views/admin/deleted_product.vue'

import adminSales from '../views/admin/sales.vue'
import adminOrder from '../views/admin/order.vue'
import adminSale from '../views/admin/sale.vue'

import adminStaffs from '../views/admin/staffs.vue'
import adminStaff from '../views/admin/staff.vue'

import adminSettings from '../views/admin/settings.vue'
import adminAccountSettings from '../views/admin/account_settings.vue'
import adminChangePassword from '../views/admin/change_password.vue'


import User from '../views/User.vue'
import userDashboard from '../views/user/dashboard.vue'
import userProducts from '../views/user/products.vue'

import userSales from '../views/user/sales.vue'
import userAdd_sale from '../views/user/add_sale.vue'

import userCustomers from '../views/user/customers.vue'

import userSettings from '../views/user/settings.vue'
import userAccountSettings from '../views/user/account_settings.vue'
import userChangePassword from '../views/user/change_password.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: adminDashboard
            },
            {
                path: 'store',
                name: 'store',
                component: adminStore
            },
            {
                path: 'category/:id',
                name: 'category',
                component: adminCategory
            },
            {
                path: 'brand/:id',
                name: 'brand',
                component: adminBrand
            },
            {
                path: 'sales',
                name: 'sales',
                component: adminSales
            },
            {
                path: 'order/:id',
                name: 'order',
                component: adminOrder
            },
            {
                path: 'sale/:id',
                name: 'sale',
                component: adminSale
            },
            {
                path: 'product/:id',
                name: 'product',
                component: adminProduct
            },
            {
                path: 'deleted_products',
                name: 'adminDeletedProducts',
                component: adminDeletedProducts
            },
            {
                path: 'staffs',
                name: 'staffs',
                component: adminStaffs
            },
            {
                path: 'staff/:id',
                name: 'staff',
                component: adminStaff
            },
            {
                path: 'settings',
                name: 'settings',
                component: adminSettings
            },
            {
                path: 'account_settings',
                name: 'account_settings',
                component: adminAccountSettings
            },
            {
                path: 'change_password',
                name: 'change_password',
                component: adminChangePassword
            }
        ]
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: userDashboard
            },
            {
                path: 'products',
                name: 'products',
                component: userProducts
            },
            {
                path: 'sales',
                name: 'sales',
                component: userSales
            },
            {
                path: 'add_sale',
                name: 'add_sale',
                component: userAdd_sale
            },
            {
                path: 'customers',
                name: 'customers',
                component: userCustomers
            },
            {
                path: 'settings',
                name: 'settings',
                component: userSettings
            },
            {
                path: 'account_settings',
                name: 'account_settings',
                component: userAccountSettings
            },
            {
                path: 'change_password',
                name: 'change_password',
                component: userChangePassword
            }
        ]
    }
]

const router = new VueRouter({
  routes
})

export default router
