<template>
    <div class="staff">
        <searchBox v-if="show_search" :placeholder="'Search staff by name'"
            @close="close_search()" @typing="search($event)"/>

        <div class="head">
            <div>Staffs</div>
            <div class="icons">
                <div class="icon" @click="popup = true">
                    <add :size="'20px'"/>
                </div>
                <div class="icon" @click="show_search = true">
                    <search :size="'20px'"/>
                </div>
            </div>
        </div>
        <div class="main">
            <loading v-if="loading"/>
            <div v-else>
                <staff v-for="staff in staffs" :key="staff.staff_id" :staff="staff"/>

                <div class="title">Deleted</div>
                <staff v-for="staff in deleted" :key="staff.staff_id" :staff="staff"/>
            </div>

        </div>
        <adminMenu/>

        <staff_add v-if="popup" 
            @close="popup = false" @new_staff="new_staff($event)"/>
    </div>
</template>

<script>
import adminMenu from '../../components/menu/admin.vue'

import add from '../../icons/add.vue';
import search from '../../icons/search.vue'

import searchBox from '../../components/util/search.vue'

import loading from '../../components/util/loading.vue';

import staff from '../../components/admin/staffs/staff.vue';

import staff_add from '../../components/admin/staffs/add.vue'

export default {
    components: {
        adminMenu, 
        add, search,
        searchBox,
        loading,
        staff,
        staff_add
    },
    data() {
        return {
            popup: false,

            loading: true,
            staffs: [],
            all_staffs: [],

            deleted: [],
            all_deleted: [],

            show_search: false
        }
    },
    mounted() {
        this.$http.post('/admin/staffs')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.all_staffs = r.staffs
                this.staffs = r.staffs

                this.all_deleted = r.deleted
                this.deleted = r.deleted
            }
            this.loading = false
        })
    },
    methods: {
        new_staff(e) {
            this.staffs.unshift(e)
        },
        search(e) {
            this.staffs = []
            for (let x of this.all_staffs) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.staffs.push(x)
                }
            }

            this.deleted = []
            for (let x of this.all_deleted) {
                if (x.name.toLowerCase().includes(e.toLowerCase())) {
                    this.deleted.push(x)
                }
            }

        },
        close_search() {
            this.staffs = this.all_staffs
            this.deleted = this.all_deleted

            this.show_search = false
        }
    }
}
</script>

<style scoped>

    .staff {
        height: 100%;
    }
    .head {
        font-weight: 800;
        padding-left: 20px;
        font-size: 18px;
        /* border: 1px solid #ddd; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        position: relative;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .icons {
        display: flex;
    }
    .icon {
        width: 62px;
        height: 62px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s;
    }
    .icon:active {
        background-color: #edeef0;
    }
    .main {
        box-sizing: border-box;
        padding: 20px;
        height: calc(100% - 129px);
        overflow: auto;
    }

    .title {
        font-weight: 800;
        margin-bottom: 20px;
    }
</style>