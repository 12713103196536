<template>
    <div class="sale">
        <div class="image">
            <img :src="imgurl + image"/>
        </div>
        <div>
            <div class="name">{{sale.product.name}} x {{ sale.quantity }}</div>
            <div class="date">{{date}}</div>
        </div>
        <div class="price">
            <div class="b">
                <vue-numeric read-only currency="$" :precision=2 separator="," v-model="total">
                </vue-numeric>
            </div>
            <div class="small">
                <vue-numeric read-only currency="$" :precision=2 separator="," v-model="sale.sale_sale_price">
                </vue-numeric> x {{ sale.quantity }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['sale'],
    data() {
        return {
            date: '',
            
            image: '',
            total: 0
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.imgurl + '/products/'
        }
    },
    mounted() {
        this.date = moment(this.sale.sale_created).format('Do MMM, yyyy - HH:mm')

        let images = JSON.parse(this.sale.product['images'])
        this.image = images[0]

        this.total = this.sale.sale_sale_price * parseFloat(this.sale.quantity)
    },
    
    methods: {
        // open_sale() {
        //     localStorage.setItem('sale', JSON.stringify(this.sale))

        //     setTimeout(() => {
        //         this.$router.push('/admin/sale/' + this.sale.sale_id)
        //     }, 1000);
        // }
    }
}
</script>

<style scoped>

    .sale {
        margin-bottom: 20px;
        background-color: white;
        border-radius: 5px;
        padding: 15px;

        display: flex;
        align-items: center;
        gap: 20px;

        transition: .2s;
    }
    .sale:active {
        background-color: #edeef0;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    .image img {
        width: 40px;
    }
    .name {
        font-weight: 500;
    }
    .date {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }

    .price {
        margin-left: auto;
    }
    .b {
        margin-left: auto;
        font-weight: 800;
    }
    .small {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }

</style>