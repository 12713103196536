<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>

        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Edit Staff</div>
                <div class="main">
                    
                    <div class="input">
                        <div class="label">Name</div>
                        <input type="text" placeholder="Enter staff name" v-model="name">
                    </div>
                    <div class="input">
                        <div class="label">Username</div>
                        <input type="text" placeholder="Enter staff username" v-model="username">
                        <div class="text">This username is used for staff login</div>
                    </div>
                    <div class="input">
                        <div class="label">Phone Number</div>
                        <input type="text" placeholder="Enter staff phone number" v-model="phone">
                    </div>
                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="btn" v-else @click="save()">Save</div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
import btn_loader from '../../util/btn_loader.vue';

export default {
    components: {
        btn_loader
    },
    props: ['staff'],
    data() {
        return {
            pop: false,

            name: '',
            username: '',
            phone: '',

            loading: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        setTimeout(() => {
            this.pop = true
        }, 100);

        this.name = this.staff.name
        this.username = this.staff.username
        this.phone = this.staff.phone
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },

        save() {
            if (this.name == '' || this.username == '' || this.phone == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('username', this.username)
            form.append('phone', this.phone)
            form.append('user_id', this.staff.user_id)

            this.$http.post('/admin/staff/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.closepop()
                    this.success()

                    this.$emit('updated', {
                        'name': this.name,
                        'phone': this.phone,
                        'username': this.username
                    })
                }
                else {
                    if (r.username) {
                        this.username_err()
                    }
                }

                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
        username_err: {
            type: 'warn',
            title: '',
            message: 'The username you entered has been used'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Staff info updated'
        }
    }
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        height: fit-content;
        box-sizing: border-box;
        overflow: auto;
    }


    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        /* color: gray; */
        font-size: 14px;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
    }
    .text {
        font-size: 12px;
        text-align: right;
    }

    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }
    

        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -353px;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>