<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M451-331q-14 14-32.5 6.5T400-352v-256q0-20 18.5-27.5T451-629l128 128q5 5 7 10t2 
            11q0 6-2 11t-7 10L451-331Z"/>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>