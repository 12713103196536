<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="m297.175-440.391 210.608 210.608q11.826 11.878 12.109 28.091.282 16.214-11.544 
            28.286-11.826 12.146-28.245 
            11.863-16.419-.283-28.451-12.109l-278-278q-6.079-6.224-9.235-13.501-3.157-7.277-3.157-14.91t3.157-14.865q3.156-7.233 
            9.235-13.42l278.565-278.565q12.674-11.827 28.49-11.827 15.815 0 27.641 11.827 11.826 12.391 
            11.826 28.31 0 15.919-11.826 27.821L297.175-519.609h477.347q16.886 0 28.53 11.326 11.644 11.326 
            11.644 28.283t-11.644 28.283q-11.644 11.326-28.53 11.326H297.175Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>