<template>
    <div id="app" :style="{'--main': c.main, '--trans': c.trans}">
        
        <router-view v-if="ready"/>
    </div>
</template>

<script>

import Localbase from 'localbase'
let db = new Localbase('Klara')

export default {
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        
        db.collection('auth').doc({ id: 1 }).get().then(document => {
            if (document == undefined) {
                this.$root.$data.login = false
                setTimeout(() => {
                    this.$router.push('/login')
                }, 1000);
                
                this.ready = true
            } else {
                this.$root.$data.login = true
                this.$root.$data.type = document.type
                this.$root.$data.dp = document.dp
                this.$root.$data.name = document.name
                this.$root.$data.phone = document.phone
                this.$root.$data.username = document.username
                this.$http.defaults.headers.common['Auth'] = document.key

                console.log(this.$route);
                
                if (document.type == 'admin') {
                    if (this.$route.path == '/') {
                        setTimeout(() => {
                            this.$router.push('/admin')
                        }, 1000);
                    }
                    setTimeout(() => {
                        if (!this.$route.path.includes('admin')) {
                            this.$router.push('/user')
                        }
                    }, 3000);
                    
                } if (document.type == 'user') {
                    if (this.$route.path == '/') {
                        setTimeout(() => {
                            this.$router.push('/user')
                        }, 1000);
                    }
                    setTimeout(() => {
                        if (!this.$route.path.includes('user')) {
                            this.$router.push('/admin')
                        }
                    }, 3000);
                }

                this.ready = true
            }
        })
    },
    computed: {
        c() {
            return this.$root.$data.c
        },
    }
}
</script>

<style>

@import 'assets/css/swiper.css';

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/montserrat.ttf');
}

#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    background-color: white;
    
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 

    /* color: #6a0dad; */
}
body {
    padding: 0px;
    margin: 0px;
}


a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
}
a:active, a:focus {
    background-color: unset;
    color: unset;
    -webkit-tap-highlight-color: transparent;
}

textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
}



.btn {
    background: linear-gradient(to left, var(--main), #1e45cf);
}



.pagination-active {
    opacity: 1;
    background-color: var(--main);
}
.swiper-container {
    /* overflow: unset; */
}

</style>
