<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M190-410q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 
            8.625-21.325T190-470h240q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 
            21.325T430-410H190Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 
            8.625-21.325T190-635h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 
            21.325T600-575H190Zm0-165q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 
            8.625-21.325T190-800h410q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 
            21.325T600-740H190Zm330 550v-81q0-5.565 2-10.783Q524-287 529-292l211.612-210.773q9.113-9.12 
            20.25-13.174Q772-520 783-520q12 0 23 4.5t20 13.5l37 37q9 9 13 20t4 22q0 11-4.5 22.5t-13.583 
            20.624L652-169q-5 5-10.217 7-5.218 2-10.783 2h-81q-12.75 0-21.375-8.625T520-190Zm263-194 
            37-39-37-37-38 38 38 38ZM580-220h38l121-122-18-19-19-18-122 121v38Zm0 0v-38l122-121 37 
            37-121 122h-38Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>