<template>
    <div class="popup">
        <div class="dark" @click="closepop()"></div>
        <transition name="slide">
            <div class="box" v-if="pop">
                <div class="title">Change staff password</div>
                <div class="main">
                    
                    <div class="input">
                        <div class="label">New password</div>
                        <input type="text" placeholder="Enter new password" v-model="password">
                        <div class="text">
                            This action will log out this staff from all devices
                        </div>
                    </div>

                    <btn_loader :height="'43px'" v-if="loading"/>
                    <div class="btn" v-else @click="save()">Change</div>
                </div>
            </div>
        </transition>

    </div>

</template>

<script>
import btn_loader from '../../util/btn_loader.vue';

export default {
    
    components: {
        btn_loader
    },
    props: ['staff'],
    data() {
        return {
            pop: false,

            password: '',
            loading: false
        }
    },
    mounted() {
        setTimeout(() => {
            this.pop = true
        }, 100);
    },
    methods: {
        closepop() {
            this.pop = false
            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },
        save() {
            if (this.password == '') {
                return
            }
            this.loading = true

            let form = new FormData()
            form.append('user_id', this.staff.user_id)
            form.append('password', this.password)
            
            this.$http.post('/admin/staff/change_password', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.closepop()
                }
            })
        }
    },
    notifications: {
        success: {
            type: 'success',
            title: '',
            message: 'Staff password has been updated'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: flex-end;
    }
    .dark {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(1px);
    }
    .box {
        background-color: white;
        width: 100%;

        position: relative;
        z-index: 10;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        padding: 20px;
        height: fit-content;
        box-sizing: border-box;
        overflow: auto;
    }


    .input {
        position: relative;
        margin-bottom: 20px;
    }
    .label {
        position: absolute;
        top: -8px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        /* color: gray; */
        font-size: 14px;
        font-weight: 500;
    }
    input, select {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
    }
    .text {
        font-size: 12px;
        margin-top: 10px;
    }

    .btn {
        padding: 12px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        border-radius: 5px;
    }
    

        
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -238px;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>